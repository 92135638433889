import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyle = makeStyles((theme) => {
    const isLightMode = theme.palette.type === Theme.Light;
    return {
        searchButton: {
            width: '100%',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            textTransform: 'none',
            fontSize: '16px',
        },
        advancedSearchButton: {
            textTransform: 'none',
            borderRadius: 50,
            padding: theme.spacing(1, 1.5),
            width: 'max-content',
            boxShadow: 'none !important',
            [theme.breakpoints.down(450)]: {
                minWidth: 'unset',
                padding: theme.spacing(1),
                borderRadius: '50%',
                '& .MuiButton-endIcon': {
                    margin: 0,
                },
            },
        },
        advancedSearchText: {
            [theme.breakpoints.down(450)]: {
                display: 'none',
            },
        },
        cardSearch: {
            height: 40,
            minWidth: 40,
            width: 40,
            textTransform: 'none',
            borderRadius: '50%',
            marginLeft: theme.spacing(1),
            transition: '.3s transform',
        },
        cardSearchHidden: {
            transform: 'scale(0)',
        },
        centered: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            transition: '.3s transform',
        },
        search: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: isLightMode ? 'rgb(255,255,255)' : 'rgb(44, 44, 44)',
            boxShadow: theme.shadows[1],
            padding: theme.spacing(2),
            borderRadius: 8,
            transition: '.3s padding',
            overflow: 'hidden',
        },
        searchOpen: {
            transform: 'translateX(48px)',
        },
        collapsed: {
            padding: theme.spacing(0.5),
        },
        input: {
            margin: 0,
            paddingLeft: theme.spacing(1.5),
            flex: 1,
            '& fieldset': {
                display: 'none',
            },
            '& input': {
                paddingLeft: 0,
            },
        },
    };
});
