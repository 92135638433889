import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useBuyJobSlotMutation } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './slot-dialog.style';

type SlotDialogProps = {
    open: boolean;
    closeDialog: () => void;
    jobId: string;
    openPricingDialog: () => void;
};

export const SlotDialog: React.FC<SlotDialogProps> = ({ open, closeDialog, jobId, openPricingDialog }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [buyJobSlot] = useBuyJobSlotMutation({
        variables: { jobId },
        onCompleted({ buyJobSlot: redirectUrl }) {
            window.location.href = redirectUrl;
        },
    });

    return (
        <Dialog open={open} fullWidth maxWidth="xs" disableBackdropClick>
            <DialogTitle disableTypography className={classes.title}>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <IconButton size="small" onClick={closeDialog} className={classes.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                    <img src={require(`assets/slot-payment.svg`)} className={classes.icon} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" alignItems="center" flexDirection="column" className={classes.content}>
                    <Typography variant="h4"> {t('pricingPlans:slotDialogTitle')}</Typography>
                    <Typography> {t('pricingPlans:slotDialogDescription')}</Typography>
                </Box>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Box display="flex" justifyContent="space-between" className={classes.buttons}>
                    <Button variant="outlined" onClick={() => openPricingDialog()} className={classes.button}>
                        {t('pricingPlans:seePlans')}
                    </Button>
                    <Button variant="contained" className={classes.button} color="primary" onClick={() => buyJobSlot()}>
                        {t('pricingPlans:increaseLimit')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
