import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        title: {
            padding: theme.spacing(2, 2.5),
        },
        content: {
            padding: theme.spacing(2.5),
        },
    };
});
