import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
    return {
        root: {
            '& .MuiSvgIcon-fontSizeSmall': {
                fontSize: '1rem',
            },
        },
    };
});
