import { Box, Button, Collapse, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import clsx from 'clsx';
import { usePagination } from 'context/filter/PaginationContext';
import { JobFilter, JOB_FILTER_DEFAULT_VALUES, useJobFilter } from 'context/JobFilterContext';
import { Field, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm } from './filter-form';
import { useStyle } from './filter.style';

export const Filter: React.FC = () => {
    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(450));
    const { t } = useTranslation();
    const { page, setPage } = usePagination();
    const { filter, setFilter, initFilter } = useJobFilter();
    const [collapseOpen, setCollapseOpen] = useState(false);

    const toggleCollapse = (): void => setCollapseOpen((prev) => !prev);

    const initialValues = {
        ...JOB_FILTER_DEFAULT_VALUES,
        ...filter,
    };

    useEffect(() => {
        initFilter();
    }, []);

    const onSubmit = (values: JobFilter, { setSubmitting }: FormikHelpers<JobFilter>): void => {
        setSubmitting(false);
        setFilter(values);

        if (page) {
            setPage(1);
        }
    };

    return (
        <Formik enableReinitialize initialValues={initialValues as JobFilter} onSubmit={onSubmit}>
            {({ values, errors, touched, setFieldValue, handleSubmit, submitForm, resetForm }): React.ReactNode => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Box className={clsx(classes.search, !collapseOpen && classes.collapsed)}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                {!collapseOpen && (
                                    <Box className={classes.centered}>
                                        <Field
                                            component={TextField}
                                            name="searchText"
                                            variant="outlined"
                                            placeholder={t('filter:searchPlaceholder')}
                                            margin="dense"
                                            className={classes.input}
                                        />
                                    </Box>
                                )}
                                <Box
                                    className={clsx(classes.centered, collapseOpen && classes.searchOpen)}
                                    style={{ flexGrow: 0 }}
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        onClick={toggleCollapse}
                                        className={classes.advancedSearchButton}
                                        variant={collapseOpen ? 'contained' : 'text'}
                                        endIcon={
                                            isMobile ? (
                                                <TuneIcon />
                                            ) : collapseOpen ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )
                                        }
                                    >
                                        <span className={classes.advancedSearchText}>{t('filter:advancedSearch')}</span>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={clsx(classes.cardSearch, collapseOpen && classes.cardSearchHidden)}
                                    >
                                        <SearchIcon />
                                    </Button>
                                </Box>
                            </Box>
                            <Collapse in={collapseOpen}>
                                <Box mt={2} display="flex" flexDirection="column">
                                    <FilterForm {...{ values, errors, touched, setFieldValue }} />
                                    <Box mt={2}>
                                        <Divider light />
                                    </Box>
                                    <Box mt={2} display="flex" justifyContent="space-between">
                                        <Button
                                            startIcon={<CloseIcon />}
                                            onClick={() =>
                                                resetForm({
                                                    values: JOB_FILTER_DEFAULT_VALUES as unknown as JobFilter,
                                                })
                                            }
                                            color="primary"
                                        >
                                            {t('filter:clearFilter')}
                                        </Button>
                                        <Button onClick={submitForm} variant="contained" color="primary">
                                            {t('common:search')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};
