import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            width: '100%',
            '& input': {
                boxSizing: 'content-box',
            },
        },
        margin: {
            margin: theme.spacing(1),
        },
        textField: {
            width: '100%',
            '& .MuiInputBase-root': {
                borderRadius: 28,
            },
        },
        icon: {
            color: theme.palette.text.secondary,
        },
    };
});
