import { Box, Typography } from '@material-ui/core';
import MuiFormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';

const FormControl = withStyles({
    root: {
        width: '100%',
    },
})(MuiFormControl);

export const useStyle = makeStyles(() => {
    return {
        title: {
            fontWeight: 600,
            marginBottom: 4,
        },
    };
});

type JobFilterItemProps = {
    title?: string;
    children: ReactNode;
};

export const JobFilterItem: React.FC<JobFilterItemProps> = ({ title, children }) => {
    const classes = useStyle();
    return (
        <Box width="100%">
            {title && (
                <Typography className={classes.title} variant="h6">
                    {title}
                </Typography>
            )}
            <FormControl>{children}</FormControl>
        </Box>
    );
};
