import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';
import React from 'react';

const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        border: {
            borderBottom: isDarkMode ? '1px solid #606060' : '1px solid #e4e5e7',
            flexGrow: 1,
        },

        content: {
            padding: ' 0 10px 0 10px',
        },
    };
});

export const DividerWithText: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <div className={classes.content}>{children}</div>
            <div className={classes.border} />
        </div>
    );
};
