import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useRef } from 'react';
import { useStyles } from './copy-to-clipboard.style';

type CopyToClipboard = {
    value: string;
    onCopied?: () => void;
    style?: React.CSSProperties;
};

export const CopyToClipboard: React.FC<CopyToClipboard> = ({ value = '', onCopied, style }) => {
    const classes = useStyles();

    const textAreaRef = useRef<any>(null);

    const copyToClipboard = (e: any): void => {
        textAreaRef.current?.lastChild?.children[0].select();
        document.execCommand('copy');
        e.target.focus();
        if (onCopied) {
            onCopied();
        }
    };

    return (
        <div className={classes.container} style={{ ...style }}>
            <TextField
                id="outlined-adornment"
                className={`${classes.margin}, ${classes.textField}`}
                variant="outlined"
                ref={textAreaRef}
                type="text"
                value={value}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton className={classes.icon} onClick={copyToClipboard}>
                                <FileCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    readOnly: true,
                }}
            />
        </div>
    );
};
