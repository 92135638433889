import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isLightMode = theme.palette.type === Theme.Light;
    return {
        link: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            borderRadius: 8,
            transition: '.3s box-shadow',
            backgroundColor: isLightMode ? 'rgb(255,255,255)' : 'rgb(44,44,44)',
            textDecoration: 'none !important',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.1)',
            '&:hover': {
                boxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
            },
        },
        iconBox: {
            display: 'flex',
            height: 'fit-content',
            borderRadius: '50%',
            marginRight: theme.spacing(1.5),
            padding: theme.spacing(1.5),
            backgroundColor: isLightMode ? 'rgb(240,240,240)' : 'rgb(52,52,52)',
        },
        title: {
            fontSize: '1rem',
            fontWeight: 600,
        },
        color0: {
            backgroundColor: 'rgba(244, 110, 110, 0.08)',
            '& svg': {
                color: 'rgb(244, 110, 110)',
            },
        },
        color1: {
            backgroundColor: 'rgb(73, 163, 246, 0.08)',
            '& svg': {
                color: 'rgb(73, 163, 246)',
            },
        },
        color2: {
            backgroundColor: 'rgb(73, 204, 246, 0.08)',
            '& svg': {
                color: 'rgb(73, 204, 246)',
            },
        },
        color3: {
            backgroundColor: 'rgb(244, 173, 73, 0.08)',
            '& svg': {
                color: 'rgb(244, 173, 73)',
            },
        },
        color4: {
            backgroundColor: 'rgb(182, 127, 253, 0.08)',
            '& svg': {
                color: 'rgb(182, 127, 253)',
            },
        },
        color5: {
            backgroundColor: 'rgb(201, 12, 215, 0.08)',
            '& svg': {
                color: 'rgb(201, 12, 215)',
            },
        },
        color6: {
            backgroundColor: 'rgb(14, 167, 111, 0.08)',
            '& svg': {
                color: 'rgb(14, 167, 111)',
            },
        },
        color7: {
            backgroundColor: 'rgb(52, 130, 210, 0.08)',
            '& svg': {
                color: 'rgb(52, 130, 210)',
            },
        },
    };
});
