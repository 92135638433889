import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { Link, LoadingButton } from 'components';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

type EasyApplyDialogProps = {
    onSubmit: any;
    closeDialog: any;
    requirementAlert: boolean;
    resumes: any[];
    loading: boolean;
    requirements: any[];
    title: string;
};

const initialValues = {
    coverLetter: '',
};

export const ApplyForm: React.FC<EasyApplyDialogProps> = ({
    onSubmit,
    closeDialog,
    requirementAlert,
    resumes,
    loading,
    requirements,
    title,
}) => {
    const { t } = useTranslation();
    return (
        <Formik {...{ initialValues, onSubmit }}>
            {({ submitForm, isSubmitting }) => (
                <>
                    <DialogTitle disableTypography>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            {title}
                            <IconButton size="small" onClick={closeDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {requirementAlert && (
                                    <Alert severity="info">{t(`applicantProfileRequirement:alertMessage`)}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    disabled={requirementAlert}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    rowsMax={10}
                                    variant="outlined"
                                    name="coverLetter"
                                    type="text"
                                    label={t('jobDetails:coverLetter')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    name="resumeId"
                                    label={t('jobDetails:selectCv')}
                                >
                                    {resumes.length > 0 ? (
                                        resumes.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                {r.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                                            <Typography variant="h5">{t('jobDetails:noResume')}</Typography>
                                        </Box>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Grid container spacing={2}>
                            {requirementAlert && (
                                <Grid item xs={12}>
                                    <Link
                                        to={{
                                            pathname: '/d/profile',

                                            state: { requirements: requirements },
                                        }}
                                    >
                                        <Button
                                            size="large"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={{ padding: 10 }}
                                        >
                                            {t('applicantProfileRequirement:goToProfile')}
                                        </Button>
                                    </Link>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    size="large"
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    style={{ padding: 10 }}
                                    loading={loading}
                                    onClick={submitForm}
                                    disabled={isSubmitting || requirementAlert}
                                    loadingText={t('jobDetails:applying')}
                                    text={t('jobDetails:apply')}
                                />
                            </Grid>
                        </Grid>
                    </DialogActions>
                </>
            )}
        </Formik>
    );
};
