import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyle = makeStyles((theme) => {
    const isLightMode = theme.palette.type === Theme.Light;
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: theme.spacing(2),
            backgroundColor: isLightMode ? 'rgb(255,255,255)' : 'rgb(44, 44, 44)',
            transition: '.3s box-shadow',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: theme.shadows[2],
            },
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff',
            marginRight: theme.spacing(2),
            flexBasis: 'unset',
            overflow: 'hidden',
            width: 65,
            height: 65,
            minWidth: 65,
            minHeight: 65,
            borderRadius: 4,
            border: isLightMode ? '1px solid rgba(0,0,0,0.1)' : '1px solid rgba(255,255,255,0.05)',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            },
        },
        defaultLogoContainer: {
            backgroundColor: isLightMode ? theme.palette.background.paper : theme.palette.background.default,
            '& img': {
                filter: isLightMode ? 'invert(0)' : 'invert(1)',
            },
        },
        title: {
            margin: 0,
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            display: '-webkit-box',
        },
        companyName: {
            fontWeight: 500,
            margin: 0,
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
            display: '-webkit-box',
        },
        verified: {
            color: theme.palette.success.main,
            borderRadius: '50%',
            textAlign: 'center',
            zIndex: 10,
            fontSize: 16,
            fontWeight: 600,
            position: 'relative',
            display: 'inline-block',
            transform: 'scale(.8)',
            marginLeft: 5,
        },
        viewIcon: {
            fontSize: '1.1rem',
            marginLeft: theme.spacing(0.5),
            color: theme.palette.text.secondary,
        },
        ticks: {
            display: 'block',
            color: '#4caf50',
            marginRight: theme.spacing(0.25),
            fontSize: '1rem',
        },
    };
});
