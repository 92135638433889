import { Card } from '@material-ui/core';
import { Pagination as MUIPagination } from '@material-ui/lab';
import { usePagination } from 'context/filter/PaginationContext';
import React, { useEffect } from 'react';
import { useStyles } from './pagination.style';

export const Pagination: React.FC = () => {
    const classes = useStyles();

    const { page, pageCount, setPage, initPage } = usePagination();

    useEffect(() => {
        initPage();
    }, []);

    const getPaginationSize = (): 'small' | 'medium' | 'large' => {
        if (window.innerWidth < 500 && window.innerWidth > 390) {
            return 'medium';
        }

        if (window.innerWidth <= 390) {
            return 'small';
        }

        return 'large';
    };

    if (!pageCount || pageCount <= 1) {
        return null;
    }
    return (
        <Card elevation={0} style={{ backgroundColor: 'transparent', marginTop: '20px' }}>
            <div className={classes.container}>
                <MUIPagination
                    color="primary"
                    classes={{
                        root: classes.pagination,
                        ul: classes.list,
                    }}
                    shape="rounded"
                    count={pageCount}
                    size={getPaginationSize()}
                    page={page || 1}
                    onChange={(_, page): void => setPage(page)}
                />
            </div>
        </Card>
    );
};
