import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import TimerOffOutlinedIcon from '@material-ui/icons/TimerOffOutlined';
import { ApplicationInviteStatus, Job, JobStatus } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSalaryText } from 'ui-services/job.ui-service';
import { getDatePosted } from 'utils/dateTimeHelper';
import { useStyle } from './job-tags.style';
import DraftsIcon from '@material-ui/icons/Drafts';

type JobTagsProps = {
    job: Job;
    status?: ApplicationInviteStatus;
    jobStatus?: JobStatus;
};
const JobTagsComponent: React.FC<JobTagsProps> = ({ job, status: inviteStatus, jobStatus }) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const { employment, salary, easyApply, status, createdAt, publishedAt } = job;

    const PostedDaysAgo = getDatePosted(status === JobStatus.Published ? publishedAt : createdAt);
    const salaryText = getSalaryText(salary);
    const statusHandleChange = (status: ApplicationInviteStatus) => {
        switch (status) {
            case ApplicationInviteStatus.Accepted:
                return classes.acceptedStatusTag;

            case ApplicationInviteStatus.Rejected:
                return classes.rejectedStatusTag;

            case ApplicationInviteStatus.Pending:
                return classes.pendingStatusTag;
        }
    };

    return (
        <ul className={classes.tags}>
            {salaryText && (
                <li className={classes.salaryTag}>
                    <WalletIcon fontSize="small" /> {salaryText}
                </li>
            )}
            {PostedDaysAgo <= 7 && <li className={classes.newBadgeTag}>{t('jobTags:new')}</li>}
            {employment && (
                <li className={classes.employmentTag}>
                    <CardTravelIcon />
                    {t(`enum:employment_${employment}`)}
                </li>
            )}
            {easyApply && (
                <li className={classes.easyApplyTag}>
                    <DoubleArrowIcon /> {t(`jobTags:easyApply`)}
                </li>
            )}
            {inviteStatus && (
                <li className={statusHandleChange(inviteStatus)}>
                    <DraftsIcon />
                    {t(`enum:inviteStatus_${inviteStatus}`)}
                </li>
            )}
            {jobStatus === JobStatus.Expired && (
                <li className={classes.expiredStatusTag}>
                    <TimerOffOutlinedIcon />
                    {t(`jobTags:expired`)}
                </li>
            )}
        </ul>
    );
};

export const JobTags = React.memo(JobTagsComponent, ({ job: prevJob }, { job: nextJob }): boolean => {
    return (
        prevJob.employment === nextJob.employment &&
        prevJob.salary === nextJob.salary &&
        prevJob.easyApply === nextJob.easyApply &&
        prevJob.status === nextJob.status &&
        prevJob.createdAt === nextJob.createdAt &&
        prevJob.publishedAt === nextJob.publishedAt
    );
});
