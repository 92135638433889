import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useNotifications } from 'context/NotificationsContext';
import React from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import { fromISODate, toUIDate } from 'utils/dateTimeHelper';

const getIconName = (extension: string): React.ReactNode => {
    const imageTypes = ['jpg', 'jpeg', 'png', 'bmp', 'tiff'];
    if (imageTypes.includes(extension)) {
        return <PhoneIcon />;
    }

    const iconsPerExtension = {
        pdf: <PictureAsPdfIcon />,
        doc: <DescriptionIcon />,
        docx: <DescriptionIcon />,
    };

    const icon = iconsPerExtension[extension];
    return icon || <AttachFileIcon />;
};

const getDescription = ({ size, createdAt }: { size: number; createdAt: string }): string => {
    const sizeInKBs = size / 1024;
    const sizeInMBs = sizeInKBs / 1024;
    const sizeText = sizeInMBs < 1 ? `${sizeInKBs.toFixed(2)} KB` : `${sizeInMBs.toFixed(2)} MB`;
    const createdText = toUIDate(fromISODate(createdAt), true);

    const descriptionParts = [`Size: ${sizeText}`];

    descriptionParts.push(`Created at ${createdText}`);

    return descriptionParts.join(' | ');
};

type AttachmentProps = {
    onDelete: any;
    url: string;
    fileName: string;
    extension: string;
    createdAt: string;
    size: number;
};

export const Attachment: React.FC<AttachmentProps> = ({ url, fileName, extension, createdAt, size, onDelete }) => {
    const { confirm } = useNotifications();

    const handleRemove = async (): Promise<void> => {
        confirm({ onConfirm: () => onDelete() });
    };

    const fileInfo = getDescription({ size, createdAt });

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>{getIconName(extension)}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography component="span" variant="body1" color="textPrimary" style={{ fontWeight: 600 }}>
                        {fileName}
                    </Typography>
                }
                secondary={
                    <>
                        <Typography component="span" variant="body2" color="textSecondary" style={{ fontWeight: 600 }}>
                            {fileInfo}
                        </Typography>
                    </>
                }
            />
            <ListItemSecondaryAction>
                <a href={url} target="blank" download>
                    <IconButton color="primary">
                        <DownloadIcon />
                    </IconButton>
                </a>
                <IconButton color="secondary" onClick={handleRemove}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
