import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { useHiringProcessListQuery } from 'graphql/generated';
import React, { useEffect } from 'react';
import { BaseField, BaseFieldProps } from '../base-field';

type WorklfowFieldProps = {
    label?: string | undefined;
    name: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
} & BaseFieldProps;

export const WorkflowField: React.FC<WorklfowFieldProps> = ({ label, name, setFieldValue, allowClear }) => {
    const { data } = useHiringProcessListQuery({
        fetchPolicy: 'network-only',
    });

    const hiringProcesses = data?.hiringProcesses.edges || [];

    useEffect(() => {
        if (hiringProcesses && hiringProcesses.length === 1) {
            setFieldValue(name, hiringProcesses[0].node.id, false);
        }
    }, [hiringProcesses]);

    return (
        <BaseField
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant="outlined"
            allowClear={allowClear}
        >
            {hiringProcesses.map(({ node: process }) => (
                <MenuItem key={process.id} value={process.id}>
                    {process.title}
                </MenuItem>
            ))}
        </BaseField>
    );
};
