import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
    return {
        root: {
            minHeight: '100%',
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3),
        },
    };
});
