import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import { ViewType } from 'enum';
import { Jobseeker } from 'graphql/generated';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './jobseeker-tags.style';

type JobseekerTagsProps = {
    jobseeker: Jobseeker;
    viewType: ViewType;
};

export const JobseekerTags: React.FC<JobseekerTagsProps> = ({ jobseeker, viewType }) => {
    const classes = useStyle();
    const { keySkills } = jobseeker;
    const { t } = useTranslation();
    const boxRef = useRef<any>();
    const [index, setIndex] = useState<number>(0);
    const refs = useRef<Array<HTMLDivElement | null>>([]);

    useEffect(() => {
        const tagsTotalWidth = refs?.current?.reduce((acc, curr) => {
            return curr ? acc + curr?.clientWidth + 6 : 0;
        }, 0);
        if (tagsTotalWidth < boxRef?.current.clientWidth) {
            setIndex(refs?.current?.length);
        } else {
            let sum = 0;
            refs?.current?.map((ref, i) => {
                if (ref) {
                    sum = sum + ref?.clientWidth + 6;
                    if (sum < boxRef?.current.clientWidth - 82) {
                        setIndex(i + 1);
                    }
                }
            });
        }
    }, []);

    return (
        <div
            className={classes.root}
            style={{ justifyContent: viewType == ViewType.Grid ? 'flex-start' : 'flex-end' }}
            ref={boxRef}
        >
            {keySkills && keySkills.length > 0 && (
                <div className={classes.skills}>
                    {index === 0 ? (
                        keySkills?.map((skill, i) => (
                            <Chip
                                className={clsx(classes.skill)}
                                key={index}
                                label={skill}
                                variant="outlined"
                                size="small"
                                ref={(el) => (refs.current[i] = el)}
                                style={{ visibility: 'hidden', position: 'absolute', width: 'unset' }}
                            />
                        ))
                    ) : index !== 0 && keySkills.length === index ? (
                        keySkills?.map((skill, i) => (
                            <Chip className={classes.skill} key={i} variant="outlined" size="small" label={skill} />
                        ))
                    ) : (
                        <>
                            {keySkills.slice(0, index).map((skill, i) => (
                                <Chip className={classes.skill} key={i} label={skill} variant="outlined" size="small" />
                            ))}
                            <Chip
                                className={classes.skill}
                                key={index}
                                variant="outlined"
                                size="small"
                                label={`${keySkills.length - index} ${t(`jobseekerList:more`)}`}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
