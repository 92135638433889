import browserHistory from 'utils/browser-utils';

export type NotificationType = 'EVENT';

export enum NotificationEventType {
    NEW_BLOG_CREATED = 'NEW_BLOG_CREATED',
    NEW_APPLICATION_REVEIVED = 'NEW_APPLICATION_REVEIVED',
}

export type NotificationData = {
    notificationType: NotificationType;
    eventType?: NotificationEventType;
    [key: string]: any;
};

type NewBlogData = {
    blogId: string;
};

const handleNewBlogEvent = (data: NewBlogData) => {
    browserHistory.push(`/blog/${data.blogId}`);
};

type NewApplicationData = {
    applicationId: string;
};

const handleNewApplicationEvent = (data: NewApplicationData) => {
    browserHistory.push(`/d/application/${data.applicationId}`);
};

const handleEventNotification = (data: NotificationData) => {
    const eventType: NotificationData['eventType'] = data.eventType;
    switch (eventType) {
        case NotificationEventType.NEW_BLOG_CREATED:
            handleNewBlogEvent({ blogId: data.blogId });
            return;
        case NotificationEventType.NEW_APPLICATION_REVEIVED:
            handleNewApplicationEvent({ applicationId: data.applicationId });
            return;
        default:
            return;
    }
};

export const handleNotification = (data: NotificationData): void => {
    switch (data.notificationType) {
        case 'EVENT':
            handleEventNotification(data);
            return;
        // Other notification types
        default:
            return;
    }
};
