import { CardContent, Grid, Hidden } from '@material-ui/core';
import { RegionInput } from 'components';
import { CompanyInput, EasyApplyField, SalaryField, WithoutSalaryField } from 'components/form';
import { CategoryField, ExperienceField } from 'components/formik';
import { JobFilter } from 'context/JobFilterContext';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './filter-form.style';
import { JobFilterItem } from './filter-item';

type FilterFormProps = {
    values: JobFilter;
    touched: FormikTouched<JobFilter>;
    errors: FormikErrors<JobFilter>;
    setFieldValue: any;
};

export const FilterForm: React.FC<FilterFormProps> = ({ values, touched, errors, setFieldValue }) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const getSalaryText = (filter: JobFilter): string => {
        const { salary } = filter;
        const salaryTextParts = [t('filter:salary')];

        if (salary?.from || salary?.to) {
            if (salary?.from !== salary?.to) {
                salaryTextParts.push(`(${salary?.from} - ${salary?.to} AZN)`);
            } else {
                salaryTextParts.push(`(${salary?.from} AZN)`);
            }
        }

        return salaryTextParts.join(' ');
    };

    const salaryText = getSalaryText(values);

    return (
        <CardContent className={classes.detail}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5}>
                    <JobFilterItem title={t('filter:searchtextTitle')}>
                        <Field
                            component={TextField}
                            name="searchText"
                            variant="outlined"
                            placeholder={t('filter:searchPlaceholder')}
                            margin="normal"
                            className={classes.textField}
                        />
                    </JobFilterItem>
                </Grid>
                <Grid item xs={8} sm={6} md={4}>
                    <JobFilterItem title={t('filter:experience')}>
                        <ExperienceField experience={values?.experience} name="experience" allowClear />
                    </JobFilterItem>
                </Grid>
                <Grid item xs={4} sm={6} md={3}>
                    <JobFilterItem title={t('filter:age')}>
                        <Field
                            component={TextField}
                            variant="outlined"
                            type="number"
                            margin="normal"
                            className={classes.textField}
                            id="age"
                            name="age"
                        />
                    </JobFilterItem>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <JobFilterItem title={t('filter:region')}>
                        <RegionInput
                            initialRegionId={values?.regionId}
                            onSelect={(regionId: string): void => setFieldValue('regionId', regionId)}
                            onReset={(): void => setFieldValue('regionId', '')}
                            touched={!!touched.regionId}
                            error={!!errors.regionId}
                        />
                    </JobFilterItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <JobFilterItem title={t('filter:category')}>
                        <CategoryField name="categoryId" allowClear />
                    </JobFilterItem>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <JobFilterItem title={t('filter:company')}>
                        <CompanyInput error={!!errors.companyId} touched={!!touched.companyId} />
                    </JobFilterItem>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <JobFilterItem title={salaryText}>
                        <SalaryField name="salary" />
                    </JobFilterItem>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={1} />
                </Hidden>
                <Grid item xs={12} sm={6} md={5} container>
                    <Grid item xs={12} md={12}>
                        <JobFilterItem title={''}>
                            <WithoutSalaryField setValue={setFieldValue} value={values.includeWithoutSalary} />
                        </JobFilterItem>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <JobFilterItem title={''}>
                            <EasyApplyField setValue={setFieldValue} value={values.onlyEasyApply} />
                        </JobFilterItem>
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    );
};
