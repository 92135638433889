import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { JobseekerStatus } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField } from '../base-field';

type JobseekerStatusFieldProps = {
    label?: string | undefined;
    name: string;
    allowClear?: boolean;
};

export const JobseekerStatusField: React.FC<JobseekerStatusFieldProps> = ({ label, name, allowClear }) => {
    const { t } = useTranslation();
    return (
        <BaseField
            allowClear={allowClear}
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant="outlined"
        >
            <MenuItem value={JobseekerStatus.Employed}>{t('enum:jobseekerStatus_EMPLOYED')}</MenuItem>
            <MenuItem value={JobseekerStatus.OpenToOpportunities}>
                {t('enum:jobseekerStatus_OPEN_TO_OPPORTUNITIES')}
            </MenuItem>
        </BaseField>
    );
};
