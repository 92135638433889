import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        leave: {
            backgroundColor: theme.palette.error.main,
            color: '#fff',
            boxShadow: 'none !important',
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
        },
    };
});
