import { makeStyles } from '@material-ui/core';
import { grey, yellow } from '@material-ui/core/colors';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    const highlightedBackground = isDarkMode ? '#3472D610' : '#3472D615';
    return {
        pricingCard: {
            padding: theme.spacing(1),
            borderRadius: 8,
            [theme.breakpoints.down('sm')]: {
                boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.1)',
                padding: theme.spacing(2),
            },
        },
        highlightedContainer: {
            backgroundColor: highlightedBackground,
            [theme.breakpoints.down('sm')]: {
                marginTop: 40,
                backgroundColor: 'transparent',
            },
        },
        highlighted: {
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: highlightedBackground,
                borderRadius: '0 0 6px 6px',
            },
        },
        highlightedHeader: {
            position: 'absolute',
            left: -8,
            right: -8,
            height: 40,
            top: -48,
            padding: theme.spacing(0, 2),
            background: theme.palette.primary.main,
            boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.3)',
            borderRadius: '4px 4px 0 0',
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            [theme.breakpoints.down('sm')]: {
                left: 0,
                right: 0,
                top: -40,
            },
        },
        highlightedBackground: {
            backgroundColor: yellow[300],
            borderRadius: 4,
            '& h6, & strong': {
                color: 'black',
            },
        },
        textGreen: {
            color: theme.palette.text.primary,
            fontWeight: 700,
        },
        textGray: {
            fontWeight: 400,
            color: isDarkMode ? grey[400] : grey[600],
        },
        limit: {
            color: theme.palette.text.primary,
        },
    };
});
