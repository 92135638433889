import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        link: {
            fontFamily: 'Inter',
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            width: '100% !important',
            padding: '10px 20px',
            lineHeight: '24px',
            color: '#fff',
            position: 'relative',
            fontSize: '16px',
            fontWeight: 600,
            display: 'inline-block',
            transition: 'all .3s ease-in-out',
            cursor: 'pointer',
            overflow: 'hidden',
            borderRadius: 8,
            [theme.breakpoints.down('md')]: {
                marginTop: '10px',
            },
        },
        slidingIcon: {},
        color: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        icon: {
            fontSize: '20px',
            width: '30px',
            top: '3px',
            position: 'relative',
            display: 'inline-block',
            marginLeft: '0px',
            paddingRight: '0px',
        },
    };
});
