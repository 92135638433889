import React from 'react';
import { IconButton, Snackbar as MUISnackbar, SnackbarContent, SnackbarCloseReason } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import { useStyles } from './snackbar.style';

export type SnackbarType = 'success' | 'error' | 'info' | 'warning';

type SnackbarProps = {
    open?: boolean;
    autoHideDuration?: number | null;
    onClose: (event?: React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => void;
    type: SnackbarType;
    message: string;
};
export const Snackbar: React.FC<SnackbarProps> = ({ open, autoHideDuration, onClose, type, message }) => {
    const classes = useStyles();
    const variantIcon = (type: string): React.ReactNode => {
        const className = `${classes.icon} ${classes.iconVariant}`;
        switch (type) {
            case 'success':
                return <CheckIcon className={className} />;
            case 'warning':
                return <WarningIcon className={className} />;
            case 'error':
                return <ErrorIcon className={className} />;
            case 'info':
                return <CheckIcon className={className} />;

            default:
                return <InfoIcon className={className} />;
        }
    };
    return (
        <MUISnackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
        >
            <SnackbarContent
                className={classes[type]}
                aria-describedby="client-snackbar"
                message={
                    <span className={classes.message} id="client-snackbar">
                        {variantIcon(type)}
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </MUISnackbar>
    );
};
