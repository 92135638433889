import { GA_API_KEY } from 'config';
import firebase from 'firebase/app';

export const logAnalyticsEvent = (
    eventName: string,
    eventParams?: {
        [key: string]: any;
    },
): void => {
    if (GA_API_KEY) {
        firebase.analytics().logEvent(eventName, eventParams);
    }
};
