import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
    return {
        root: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
            display: 'flex',
            justifyContent: 'space-between',
        },
        container: {
            alignItems: 'center',
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(1),
        },
        select: {
            ...theme.typography.h6,
            '& fieldset': {
                display: 'none',
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
            '& .MuiOutlinedInput-input': {
                padding: theme.spacing(1),
                paddingRight: theme.spacing(4),
            },
        },
    };
});
