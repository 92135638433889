import { Grid, makeStyles } from '@material-ui/core';
import { CategoryEdge } from 'graphql/generated';
import React from 'react';
import { Category } from './category';

const useStyle = makeStyles({
    list: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

type CategoryListProps = {
    categories: CategoryEdge[];
};

const CategoryListComponent: React.FC<CategoryListProps> = ({ categories }) => {
    const classes = useStyle();

    return (
        <Grid spacing={2} container className={classes.list}>
            {categories.map(({ node: category }, i) => (
                <Grid key={category.id} item xs={12} sm={6} lg={3}>
                    <Category category={category} index={i} />
                </Grid>
            ))}
        </Grid>
    );
};

export const CategoryList = React.memo(CategoryListComponent, (prevProps, nextProps): boolean => {
    return prevProps.categories === nextProps.categories;
});
