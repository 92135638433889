import TableCell from '@material-ui/core/TableCell';
import { Link } from 'components';
import { Job } from 'graphql/generated';
import React from 'react';
import { getSalaryText } from 'ui-services/job.ui-service';
import { getDateText } from 'utils/dateTimeHelper';
import { useStyles } from './job-table-item.style';

type JobTableItemProps = {
    job: Job;
};

const JobTableItemComponent: React.FC<JobTableItemProps> = ({ job }) => {
    const { slug, title, publishedAt, expiresAt, company, salary } = job;

    const newTitle = title.split(' ').length > 5 ? title.split(' ').slice(0, 5).join(' ') + '...' : title;
    const classes = useStyles();

    return (
        <Link to={`/job/${slug}`} className={classes.root}>
            <TableCell component="th" scope="row">
                {newTitle}
            </TableCell>
            <TableCell align="right">
                <Link to={company.slug ? `/${company.slug}` : `/company/${company.id}`} link>
                    {company.name}
                </Link>
            </TableCell>
            <TableCell align="right" style={{ width: 'max-content' }}>
                {getSalaryText(salary, '-')}
            </TableCell>
            <TableCell align="right">{getDateText(publishedAt)}</TableCell>
            <TableCell align="right">{getDateText(expiresAt)}</TableCell>
        </Link>
    );
};

export const JobTableItem = React.memo(JobTableItemComponent, (prevProps, nextProps): boolean => {
    return prevProps.job === nextProps.job;
});
