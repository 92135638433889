import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { Experience } from 'graphql/generated';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { BaseField, BaseFieldProps } from '../base-field';

type ExperienceFieldProps = {
    label?: string | undefined;
    name: string;
} & BaseFieldProps;

export const ExperienceField: React.FC<ExperienceFieldProps> = ({ label, name, allowClear }) => {
    const { t } = useTranslation();

    return (
        <BaseField
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant="outlined"
            allowClear={allowClear}
        >
            <MenuItem value={Experience.NoExperience}>{t('filter:noExperience')}</MenuItem>
            <MenuItem value={Experience.LessThanOne}>{t('filter:lessThanOne')}</MenuItem>
            <MenuItem value={Experience.BetweenOneAndThree}>{t('filter:betweenOneAndThree')}</MenuItem>
            <MenuItem value={Experience.BetweenThreeAndSix}>{t('filter:betweenThreeAndSix')}</MenuItem>
            <MenuItem value={Experience.MoreThanSix}>{t('filter:moreThanSix')}</MenuItem>
        </BaseField>
    );
};
