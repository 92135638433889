import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
    return {
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 36,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },

            '& button': {
                marginLeft: '10px',
            },
        },

        title: {
            fontWeight: 500,
        },

        rightContainer: {
            justifyItems: 'flex-end',
        },
    };
});
