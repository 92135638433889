import clsx from 'clsx';
import React, { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useStyle } from './page.style';

type PageProps = {
    children: ReactNode;
    title?: string;
    className?: string;
    style?: React.CSSProperties;
};

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', className, ...rest }, ref) => {
    const classes = useStyle();

    const pageTitle = title ? `Staffy - ${title}` : 'Staffy';

    return (
        <div ref={ref} className={clsx(classes.root, className)} {...rest}>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {children}
        </div>
    );
});

Page.displayName = 'Page';

export { Page };
