import { Job } from 'graphql/generated';
import React from 'react';
import { Helmet } from 'react-helmet';
import { JobPosting } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';

type StructuredJobDataProps = {
    job: Job;
};

export const StructuredJobData: React.FC<StructuredJobDataProps> = ({ job }) => {
    return (
        <Helmet
            script={[
                helmetJsonLdProp<JobPosting>({
                    '@context': 'https://schema.org',
                    '@type': 'JobPosting',
                    title: job.title,
                    description: job.descriptionHtml,
                    identifier: {
                        '@type': 'PropertyValue',
                        name: 'Staffy',
                        value: job.id,
                    },
                    datePosted: job.publishedAt,
                    validThrough: job.expiresAt,
                    employmentType: job.employment || 'FUL_TIME',
                    hiringOrganization: {
                        '@type': 'Organization',
                        name: job.company.name,
                        logo: job.company.logo?.url,
                    },
                    jobLocation: {
                        '@type': 'Place',
                        address: {
                            '@type': 'PostalAddress',
                            postalCode: 'AZ1000',
                            streetAddress: job.address?.street || '1/25 Ahmad Rajabli, Baku, Azerbaijan',
                            addressLocality: job.address?.city || 'Narimanov',
                            addressRegion: job.address?.city || 'Baki',
                        },
                    },
                    baseSalary: {
                        '@type': 'MonetaryAmount',
                        currency: job.salary?.currency || 'AZN',
                        value: {
                            '@type': 'MonetaryAmount',
                            minValue: job.salary?.from || 0,
                            maxValue: job.salary?.to || 0,
                            value: job.salary?.gross || 0.0,
                        },
                    },
                }),
            ]}
        />
    );
};
