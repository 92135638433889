import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyle = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        header: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: theme.spacing(2),
            '& p': {
                fontSize: theme.spacing(3),
            },
        },
        icon: {
            width: '100px',
            margin: theme.spacing(3, 0, 2, 0),
        },
        closeIcon: {
            display: 'flex',
            alignSelf: 'flex-end',
        },
        confirmButton: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
        },
        headerWrapper: {
            backgroundColor: isDarkMode ? theme.palette.action.hover : '',
            padding: theme.spacing(2),
        },
        contentWrapper: {
            backgroundColor: isDarkMode ? '' : theme.palette.action.hover,
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: theme.spacing(3),
        },
        description: {
            marginBottom: theme.spacing(3),
            textAlign: 'center',
        },
        buttons: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        button: {
            margin: theme.spacing(1),
            padding: theme.spacing(0.8, 2),
            width: '35%',
        },
    };
});
