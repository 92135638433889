import { Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './tag-item.style';

export const TagItem: React.FC<BoxProps & { tag: string }> = ({ tag, className, ...props }) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.root, className)} {...props}>
            {tag}
        </Box>
    );
};
