import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
    return {
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            '& img': {
                width: '200px',
            },
        },
    };
});
