import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        actions: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(4),
        },
        title: {
            padding: theme.spacing(2, 2),
        },
        closeIcon: {
            alignSelf: 'flex-end',
        },
        icon: {
            width: '80px',
            margin: theme.spacing(2, 0, 2, 0),
        },
        content: {
            '& *': {
                textAlign: 'center',
            },
            '& *:first-child': {
                marginBottom: theme.spacing(2),
            },
            '& *:last-child': {
                width: '80%',
            },
        },
        buttons: {
            width: '80%',
        },
        button: {
            fontSize: theme.spacing(1.8),
            width: '48%',
            padding: theme.spacing(0.8, 0),
        },
    };
});
