import { IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { RegionConnection, useRegionListQuery } from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionModal } from './RegionModal';

type RegionInputProps = {
    label?: string;
    placeholder?: string;
    onSelect: any;
    onReset?: any;
    initialRegionId?: string;
    touched?: boolean;
    error?: boolean;
};

const getRegionList = (regions: any): any => {
    const regionList: Array<any> = [];

    regions?.edges?.map((r) => {
        regionList.push({
            id: r.node.id,
            name: r.node.name,
        });
        if (r.node.children) {
            const childRegions = getRegionList(r.node.children);
            regionList.push(...childRegions);
        }
    });

    return regionList;
};

export const RegionInput: React.FC<RegionInputProps> = ({
    label,
    placeholder,
    onSelect,
    onReset,
    error,
    initialRegionId,
}) => {
    const { data } = useRegionListQuery({
        fetchPolicy: 'cache-first',
    });
    const { t } = useTranslation();
    const theme = useTheme();
    const regions = data?.regions || [];

    const regionList = getRegionList(regions);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [regionId, setRegionId] = useState(initialRegionId);
    const [regionName, setRegionName] = useState('');

    const showClearIcon = !!regionId;

    const handleRegionChange = (value): void => {
        const region = regionList.find((r) => r.id === value);
        setRegionName(region.name);
        setRegionId(value);
    };

    const saveRegion = (): void => {
        setIsModalOpen(false);
        onSelect(regionId);
    };

    const cancelSelection = (): void => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const region = regionList.find((r) => r.id === initialRegionId);
        setRegionName(region?.name || '');
    }, [data, initialRegionId]);

    return (
        <>
            <TextField
                variant="outlined"
                label={label}
                placeholder={placeholder}
                fullWidth
                error={error}
                value={t(`region:${regionName}`)}
                autoComplete="off"
                onClick={(): void => setIsModalOpen(true)}
                inputProps={{ style: { cursor: 'pointer', color: theme.palette.text.primary } }}
                InputProps={{
                    disabled: true,
                    error,
                    style: { paddingRight: 0 },
                    endAdornment: (
                        <InputAdornment position="end">
                            {showClearIcon && (
                                <IconButton
                                    aria-label="Regions"
                                    size="small"
                                    onClick={(event): void => {
                                        event.stopPropagation();
                                        setRegionName('');
                                        setRegionId('');
                                        onReset();
                                    }}
                                >
                                    <CloseIcon style={{ fontSize: 16 }} />
                                </IconButton>
                            )}
                            <IconButton
                                aria-label="Regions"
                                style={{ backgroundColor: 'transparent' }}
                                size="small"
                                onClick={(): void => setIsModalOpen(true)}
                            >
                                <ArrowDropDownIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <RegionModal
                regions={regions as RegionConnection}
                isModalOpen={isModalOpen}
                onChange={handleRegionChange}
                onSave={saveRegion}
                onCancel={cancelSelection}
                regionId={regionId}
            />
        </>
    );
};
