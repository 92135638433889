import MenuItem from '@material-ui/core/MenuItem';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { LanguageLevel } from 'graphql/generated';
import React from 'react';

type LanguageLevelFieldProps = {
    label?: string | undefined;
    name: string;
};

export const LanguageLevelField: React.FC<LanguageLevelFieldProps> = ({ label, name }) => {
    return (
        <Field label={label} name={name} component={TextField} select fullWidth variant="outlined">
            <MenuItem value={LanguageLevel.A1}>{LanguageLevel.A1}</MenuItem>
            <MenuItem value={LanguageLevel.A2}>{LanguageLevel.A2}</MenuItem>
            <MenuItem value={LanguageLevel.B1}>{LanguageLevel.B1}</MenuItem>
            <MenuItem value={LanguageLevel.B2}>{LanguageLevel.B2}</MenuItem>
            <MenuItem value={LanguageLevel.C1}>{LanguageLevel.C1}</MenuItem>
            <MenuItem value={LanguageLevel.C2}>{LanguageLevel.C2}</MenuItem>
        </Field>
    );
};
