import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        root: {
            display: 'table-row',
            '&:hover': {
                backgroundColor: isDarkMode ? '#333' : 'rgb(242, 244, 246)',
            },
        },
    };
});
