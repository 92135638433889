import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { useCategoryListQuery } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseFieldProps } from '../base-field/base-field';
import { BaseField } from '../base-field';

type CategoryFieldProps = Omit<BaseFieldProps, 'margin'> & {
    label?: string | undefined;
    name: string;
    margin?: 'normal' | 'dense' | boolean;
};

export const CategoryField: React.FC<CategoryFieldProps> = ({
    label,
    name,
    margin,
    allowClear,
    variant = 'outlined',
    ...props
}) => {
    const { t } = useTranslation();
    const { data } = useCategoryListQuery({
        fetchPolicy: 'cache-and-network',
    });

    const categories = data?.categories.edges || [];
    const marginProps =
        margin === false
            ? {
                  margin: 'dense',
                  style: { margin: 0 },
              }
            : {
                  margin,
              };

    return (
        <BaseField
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant={variant}
            {...props}
            {...marginProps}
            allowClear={allowClear}
        >
            {categories.map(({ node: category }) => (
                <MenuItem key={category.id} value={category.id}>
                    {t(`category:${category.name}`)}
                </MenuItem>
            ))}
        </BaseField>
    );
};
