import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        title: {
            backgroundColor: isDarkMode ? '#444' : '#f9f9f9',
            padding: theme.spacing(1),
            borderRadius: 8,
            fontSize: '1.2rem',
        },

        root: {
            paddingTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        selects: {
            width: theme.spacing(20),
        },
    };
});
