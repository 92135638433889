import { Box, Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'components';
import { categoryIcons } from 'data/category-icons';
import { Category as ICategory } from 'graphql/generated';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useStyles } from './category.style';

type CategoryProps = {
    category: ICategory;
    index: number;
};

const CategoryCompnent: React.FC<CategoryProps> = ({ category, index }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { jobCount, name } = category;
    return (
        <Link to={`/jobs?categoryId=${category.id}&page=1`}>
            <Card className={classes.link}>
                <Box display="flex" alignItems="center">
                    <Box className={clsx(classes.iconBox, classes[`color${index}`])}>
                        {categoryIcons[category.name]({ size: 1.2 })}
                    </Box>
                    <Typography className={classes.title}>{t(`category:${name}`)}</Typography>
                </Box>
                <Box flexGrow={1} mt={2}>
                    <Typography
                        variant="body1"
                        style={{ fontWeight: 500, textTransform: 'lowercase' }}
                        color="textSecondary"
                    >
                        {jobCount} {t('homePage:jobCount')}
                    </Typography>
                </Box>
            </Card>
        </Link>
    );
};

export const Category = React.memo(CategoryCompnent, (prevProps, nextProps): boolean => {
    return prevProps.category === nextProps.category && prevProps.index === nextProps.index;
});
