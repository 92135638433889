import { Box, Card, Tooltip, Typography, Zoom } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import clsx from 'clsx';
import { Bookmark, JobTags, Link } from 'components';
import { defaultCompanyLogo } from 'config';
import { useAuth } from 'context/AuthContext';
import { Job, JobStatus } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateText } from 'utils/dateTimeHelper';
import { useStyle } from './job-list-item.style';

type JobListItemProps = {
    job: Job;
    bookmarkJob?: any;
    showCompany?: boolean;
};

const JobListItemComponent: React.FC<JobListItemProps> = ({ bookmarkJob, job, showCompany = true }) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const {
        id,
        slug,
        title,
        bookmarked,
        status,
        publishedAt,
        viewCount,
        createdAt,
        applied,
        salary,
        employment,
        easyApply,
        company: { name, verified, logo },
    } = job;

    const { isLoggedIn, openModal, isEmployer, isAdmin } = useAuth();
    const hasTags = salary?.from || salary?.to || createdAt || publishedAt || employment || easyApply;

    return (
        <Link to={`/job/${slug}`}>
            <Card className={classes.container}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" mb={1}>
                        <Box className={clsx(classes.logoContainer, !logo?.url && classes.defaultLogoContainer)}>
                            <img src={logo?.url || defaultCompanyLogo} alt="Company Logo" />
                        </Box>
                        <Box flexGrow={1}>
                            <Box display="flex" flexWrap="wrap">
                                <Typography className={classes.title} variant="h5">
                                    {title}
                                </Typography>
                                {applied && (
                                    <Box display="flex" alignItems="center">
                                        <DoneAllIcon fontSize="small" className={classes.ticks} />
                                        <Typography
                                            variant="h6"
                                            color="textSecondary"
                                            style={{ fontWeight: 500, fontSize: '0.8rem' }}
                                        >
                                            {t('jobTags:applied')}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            {showCompany && (
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.companyName} variant="h6" color="textSecondary">
                                        {name}
                                    </Typography>
                                    {verified && (
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            placement="top"
                                            title={t('jobDetails:verifiedEmployer') as string}
                                        >
                                            <CheckCircleIcon className={classes.verified} />
                                        </Tooltip>
                                    )}
                                </Box>
                            )}
                            {hasTags && (
                                <Box mt={0.5}>
                                    <JobTags job={job as Job} />
                                </Box>
                            )}
                        </Box>
                        {!isEmployer && !isAdmin && bookmarkJob && (
                            <Box>
                                <Tooltip
                                    TransitionComponent={Zoom}
                                    placement="top"
                                    title={
                                        bookmarked
                                            ? (t('jobDetails:removeBookmarkTitle') as string)
                                            : (t('jobDetails:bookmarkTitle') as string)
                                    }
                                >
                                    <div>
                                        <Bookmark
                                            onClick={(): void => {
                                                if (!isLoggedIn) {
                                                    openModal();
                                                } else {
                                                    bookmarkJob({
                                                        variables: {
                                                            jobId: id,
                                                        },
                                                    });
                                                }
                                            }}
                                            bookmarked={bookmarked}
                                            size="small"
                                        />
                                    </div>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" color="textSecondary" style={{ fontWeight: 500, marginRight: 8 }}>
                                {getDateText(status === JobStatus.Published ? publishedAt : createdAt)}
                            </Typography>
                        </Box>
                        {isAdmin && (
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" color="textSecondary" style={{ fontWeight: 500 }}>
                                    {viewCount || 0}
                                </Typography>
                                <VisibilityOutlinedIcon className={classes.viewIcon} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Card>
        </Link>
    );
};

export const JobListItem = React.memo(JobListItemComponent, (prevProps, nextProps): boolean => {
    return prevProps.job === nextProps.job;
});
