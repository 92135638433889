import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

const tagStyle = {
    width: 'fit-content',
    padding: '4px 16px',
    fontSize: '13px',
    borderRadius: 2,
    fontWeight: 600,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
};

export const useStyle = makeStyles((theme) => {
    const isLightMode = theme.palette.type === Theme.Light;
    return {
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            paddingLeft: 13,
            backgroundColor: isLightMode ? 'rgb(255,255,255)' : 'rgb(44, 44, 44)',
            transition: '.3s border-left',
            borderLeft: '3px solid transparent',
            '&:hover': {
                borderLeft: `3px solid ${theme.palette.primary.main}`,
            },
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        title: {
            fontSize: '1.125rem',
            fontWeight: 600,
            marginRight: theme.spacing(3),
        },
        tag: {
            marginRight: 10,
        },
        publishButton: {
            backgroundColor: 'rgba(76, 175, 80)',
            color: theme.palette.success.contrastText,
            marginRight: theme.spacing(1),
            height: 36,
            padding: theme.spacing(1, 2),
            textTransform: 'none',
            boxShadow: 'none !important',
            '&:hover': {
                backgroundColor: 'rgba(56, 155, 60)',
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
                minWidth: 'unset',
                '& .MuiButton-startIcon': {
                    margin: 0,
                },
            },
        },
        statusOnModeration: {
            ...tagStyle,
            color: '#ff9800',
            backgroundColor: 'rgba(255, 152, 0, 0.08)',
        },
        statusPublished: {
            ...tagStyle,
            color: '#4caf50',
            backgroundColor: 'rgba(76, 175, 80, 0.08)',
        },
        statusBlocked: {
            ...tagStyle,
            color: '#f44336',
            backgroundColor: 'rgba(244, 67, 54, 0.08)',
        },
        viewCountTag: {
            ...tagStyle,
            color: isLightMode ? '#111' : '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '& span': {
                fontSize: 16,
                marginRight: 4,
            },
        },
        statusDraft: {
            ...tagStyle,
            color: 'rgb(213,0,249)',
            backgroundColor: 'rgba(213,0,249, 0.08)',
        },
        candidateCount: {
            ...tagStyle,
            padding: '4px 8px',
            color: '#2196f3',
            backgroundColor: 'rgba(33, 150, 243, 0.08)',
        },
        date: {
            ...tagStyle,
            padding: '4px 8px',
            backgroundColor: 'rgba(97, 97, 97, 0.08)',
        },
        tagIcon: {
            marginRight: 4,
            fontSize: 16,
        },
        manageButton: {
            borderRadius: 2,
            marginRight: '8px',
            textTransform: 'none',
            marginTop: 10,
        },
        menu: {
            minWidth: 200,
        },
        menuItem: {
            padding: theme.spacing(1.5, 2),
            ...theme.typography.h5,
            color: theme.palette.text.secondary,
            fontWeight: 500,
        },
        menuItemIcon: {
            ...theme.typography.h5,
            marginRight: 10,
        },
    };
});
