/* eslint-disable @typescript-eslint/no-empty-function */
import { PricingPlanDialog, SlotDialog } from 'components';
import { FeatureCode, PurchasedPlan } from 'graphql/generated';
import React, { createContext, useContext, useState } from 'react';

type PricingPlanContextProps = {
    withFeatureCheck: (currentPlan: PurchasedPlan, featureCode: FeatureCode, callback: any, params?: any) => void;
    showPricingDialog: (featureCode: FeatureCode) => void;
    isFeatureEnabled: (currentPlan: PurchasedPlan, featureCode: FeatureCode) => boolean;
    showSlotDialog: (params: any) => void;
    openPricingDialog: () => void;
    openSlotDialog: () => void;
};

const PricingPlanContext = createContext<PricingPlanContextProps>({
    withFeatureCheck: () => () => {},
    showPricingDialog: () => {},
    isFeatureEnabled: () => {
        return true;
    },
    showSlotDialog: () => {},
    openPricingDialog: () => {},
    openSlotDialog: () => {},
});

export const usePricingPlan = (): PricingPlanContextProps => useContext(PricingPlanContext);

export const PricingPlanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [pricingDialogOpen, setPricingDialogOpen] = useState(false);
    const [slotDialogOpen, setSlotDialogOpen] = useState(false);
    const [highlightedFeature, setHighlightedFeature] = useState(undefined);
    const [jobId, setJobId] = useState('');

    const openPricingDialog = (): void => setPricingDialogOpen(true);
    const openSlotDialog = (): void => setSlotDialogOpen(true);

    const closePricingDialog = (): void => setPricingDialogOpen(false);
    const closeSlotDialog = (): void => setSlotDialogOpen(false);

    const showPricingDialog = (featureCode): void => {
        setHighlightedFeature(featureCode);
        openPricingDialog();
    };

    const showSlotDialog = (jobId: string): void => {
        setJobId(jobId);
        openSlotDialog();
    };

    const isFeatureEnabled = (currentPlan: PurchasedPlan, featureCode: FeatureCode) => {
        if (!currentPlan) {
            return false;
        }

        const enabledFeatures =
            currentPlan.features.edges.filter(({ node: feature }) => feature.enabled).map(({ node }) => node) || [];
        const feature = enabledFeatures?.find((feature) => feature.code === featureCode);
        return !!currentPlan.active && !!feature;
    };

    const hasLimit = (currentPlan: PurchasedPlan, featureCode: FeatureCode) => {
        const feature = currentPlan.features.edges.find(({ node: feature }) => feature.code === featureCode)?.node;
        const limit = feature?.limit;
        if (limit === 0) return false;
        return true;
    };

    const withFeatureCheck = (
        currentPlan: PurchasedPlan,
        featureCode: FeatureCode,
        callback: any,
        errorCallback?: any,
    ) => {
        if (isFeatureEnabled(currentPlan, featureCode)) {
            if (hasLimit(currentPlan, featureCode)) {
                callback();
            } else {
                errorCallback?.();
            }
        } else {
            showPricingDialog(featureCode);
        }
    };

    return (
        <PricingPlanContext.Provider
            value={{
                showPricingDialog,
                withFeatureCheck,
                isFeatureEnabled,
                showSlotDialog,
                openPricingDialog,
                openSlotDialog,
            }}
        >
            {children}
            <PricingPlanDialog {...{ open: pricingDialogOpen, highlightedFeature, closeDialog: closePricingDialog }} />
            <SlotDialog {...{ open: slotDialogOpen, closeDialog: closeSlotDialog, jobId, openPricingDialog }} />
        </PricingPlanContext.Provider>
    );
};
