/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';
import { extractOrderByFromQueryString, setSearchParams } from 'utils/browser-utils';
import { once } from 'lodash';

type SortingContextProps<T> = {
    orderBy?: T;
    initOrderBy: () => void;
    setOrderBy: (orderBy: T) => void;
};

const SortingContext = once(<T,>() =>
    createContext<SortingContextProps<T>>({
        initOrderBy: () => {},
        setOrderBy: () => {},
    }),
);

export const useSorting = <T,>(): SortingContextProps<T> => useContext(SortingContext<T>());

export const SortingProvider: React.FC<{ children: React.ReactNode }> = <T,>({ children }) => {
    const [orderByValue, setOrderByValue] = useState<T>();

    const setOrderBy = (orderBy: T): void => {
        setOrderByValue(orderBy);
        setSearchParams({ orderBy });
    };

    const initOrderBy = (): void => {
        const orderBy = extractOrderByFromQueryString<T>();
        setOrderByValue(orderBy);
    };

    const Context = SortingContext<T>();

    return <Context.Provider value={{ orderBy: orderByValue, initOrderBy, setOrderBy }}>{children}</Context.Provider>;
};
