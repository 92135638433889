import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: theme.spacing(1.5, 0),
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        list: {
            '& button': {
                borderRadius: '50%',
                fontWeight: 600,
            },
        },
    };
});
