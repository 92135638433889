import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { Schedule } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField, BaseFieldProps } from '../base-field';

type ScheduleFieldProps = {
    label?: string | undefined;
    name: string;
} & BaseFieldProps;

export const ScheduleField: React.FC<ScheduleFieldProps> = ({ label, name, allowClear }) => {
    const { t } = useTranslation();
    return (
        <BaseField
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant="outlined"
            allowClear={allowClear}
        >
            <MenuItem value={Schedule.FullDay}>{t('enum:schedule_FullDay')}</MenuItem>
            <MenuItem value={Schedule.Shift}>{t('enum:schedule_Shift')}</MenuItem>
            <MenuItem value={Schedule.Flexible}>{t('enum:schedule_Flexible')}</MenuItem>
            <MenuItem value={Schedule.Remote}>{t('enum:schedule_Remote')}</MenuItem>
            <MenuItem value={Schedule.FlyInFlyOut}>{t('enum:schedule_FlyInFlyOut')}</MenuItem>
        </BaseField>
    );
};
