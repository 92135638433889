import { Box, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { LoadingButton } from 'components';
import { useAuth } from 'context/AuthContext';
import { useNotifications } from 'context/NotificationsContext';
import { FeatureCode, PricingPlan, PricingType, useUpdatePricingPlanMutation } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './pricing-plan-item.style';

type PricingPlanItemProps = {
    plan: PricingPlan;
    isHighlighted?: boolean;
    highlightedFeature?: FeatureCode;
    saving?: boolean;
    setSaving?: any;
    closeDialog?: any;
};

export const PricingPlanItem: React.FC<PricingPlanItemProps> = ({
    plan,
    isHighlighted,
    highlightedFeature,
    setSaving,
    closeDialog,
    saving = false,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { notify } = useNotifications();
    const { isLoggedIn, openModal } = useAuth();
    const features = plan.features.edges.map(({ node }) => node) || [];
    const disabled =
        (highlightedFeature && !features.find((feature) => feature.code === highlightedFeature)?.enabled) || saving;

    const [updatePricingPlan, { loading }] = useUpdatePricingPlanMutation({
        onCompleted({ updatePricingPlan }) {
            window.open(updatePricingPlan, '_self');
            setSaving(false);
            if (closeDialog) {
                closeDialog();
            }
        },
        onError(error) {
            setSaving(false);
            notify({
                type: 'error',
                message: t(`error:${error.message}`) || `${t('defaultErrorMessage')}`,
            });
        },
    });

    return (
        <Grid
            key={plan.id}
            item
            xs={12}
            sm={6}
            md={3}
            style={{ zIndex: 10 }}
            className={clsx(isHighlighted && classes.highlightedContainer)}
        >
            <Box className={clsx(classes.pricingCard, isHighlighted && classes.highlighted)}>
                {isHighlighted && (
                    <Box className={classes.highlightedHeader}>
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                            {t('pricingPlans:mostPopular')}
                        </Typography>
                    </Box>
                )}
                <Typography variant="h3">{plan.name}</Typography>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h5">₼&nbsp;</Typography>
                    <Typography variant="h4">{plan.price}&nbsp;</Typography>
                    <Typography variant="body1" className={classes.textGray}>
                        / {t(`pricingPlans:${plan.pricingType}`)}
                    </Typography>
                </Box>
                <Box my={2}>
                    <LoadingButton
                        onClick={() => {
                            if (isLoggedIn) {
                                setSaving(true);
                                updatePricingPlan({ variables: { pricingPlanId: plan.id } });
                            } else {
                                openModal();
                            }
                        }}
                        fullWidth
                        color="primary"
                        disabled={disabled || loading}
                        variant="contained"
                        loading={loading}
                        loadingText={t('pricingPlans:selecting')}
                        text={t('pricingPlans:select')}
                    />
                </Box>
                <Divider light />
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {features.map(
                            (feature) =>
                                feature.code !== 'FREE_JOB_LISTING_PROMOTION' && (
                                    <Grid
                                        key={feature.id}
                                        className={clsx(
                                            feature.code === highlightedFeature &&
                                                feature.enabled &&
                                                classes.highlightedBackground,
                                        )}
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            variant="h6"
                                            className={clsx(feature.enabled ? classes.textGreen : classes.textGray)}
                                        >
                                            {t(`pricingPlans:${feature.code}`)}
                                            {!(
                                                feature.code === FeatureCode.CreateJobListing &&
                                                plan.pricingType === PricingType.PayAsYouGo
                                            ) && (
                                                <Tooltip arrow title={t('pricingPlans:limit') || ''} placement="top">
                                                    <strong className={classes.limit}>
                                                        {feature.limit !== null ? (
                                                            <> ({feature.limit}) </>
                                                        ) : feature.code === 'CREATE_JOB_LISTING' ? (
                                                            <> ({t('pricingPlans:unlimited')}) </>
                                                        ) : null}
                                                    </strong>
                                                </Tooltip>
                                            )}
                                        </Typography>
                                    </Grid>
                                ),
                        )}
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
};
