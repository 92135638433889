/* eslint-disable @typescript-eslint/no-empty-function */
import { Experience } from 'graphql/generated';
import React, { createContext, useContext, useState } from 'react';
import { extractFilterFromQS } from 'ui-services/job.ui-service';
import { setSearchParams } from 'utils/browser-utils';

export const JOB_FILTER_DEFAULT_VALUES = {
    searchText: '',
    categoryId: '',
    companyId: '',
    regionId: '',
    age: '',
    experience: '',
    onlyEasyApply: '',
    includeWithoutSalary: '',
    salary: {
        from: 0,
        to: 0,
    },
};

export type JobFilter = {
    searchText?: string;
    categoryId?: string;
    companyId?: string;
    regionId?: string;
    age?: number;
    experience?: Experience;
    onlyEasyApply: boolean;
    includeWithoutSalary: boolean;
    salary?: {
        from?: number;
        to?: number;
    };
};

type JobFilterContextProps = {
    filter?: JobFilter;
    initFilter: () => void;
    setFilter: (filter: JobFilter) => void;
};

const JobFilterContext = createContext<JobFilterContextProps>({
    initFilter: () => {},
    setFilter: () => {},
});

export const useJobFilter = (): JobFilterContextProps => useContext<JobFilterContextProps>(JobFilterContext);

export const JobFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [filterValue, setFilterValue] = useState<JobFilter>({} as JobFilter);

    const setFilter = (filter: JobFilter): void => {
        setFilterValue(filter);
        setSearchParams({
            ...filter,
            salary: undefined,
            salaryMin: filter?.salary?.from || undefined,
            salaryMax: filter?.salary?.to || undefined,
        });
    };

    const initFilter = (): void => {
        const filter = extractFilterFromQS();
        setFilterValue(filter);
    };

    return (
        <JobFilterContext.Provider value={{ filter: filterValue, initFilter, setFilter }}>
            {children}
        </JobFilterContext.Provider>
    );
};
