import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { Employment } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField, BaseFieldProps } from '../base-field';

type EmploymentFieldProps = {
    label?: string | undefined;
    name: string;
} & BaseFieldProps;

export const EmploymentField: React.FC<EmploymentFieldProps> = ({ label, name, allowClear }) => {
    const { t } = useTranslation();
    return (
        <BaseField
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant="outlined"
            allowClear={allowClear}
        >
            <MenuItem value={Employment.FullTime}>{t('enum:employment_FULL_TIME')}</MenuItem>
            <MenuItem value={Employment.PartTime}>{t('enum:employment_PART_TIME')}</MenuItem>
            <MenuItem value={Employment.Freelance}>{t('enum:employment_FREELANCE')}</MenuItem>
            <MenuItem value={Employment.Volunteer}>{t('enum:employment_VOLUNTEER')}</MenuItem>
            <MenuItem value={Employment.Probation}>{t('enum:employment_PROBATION')}</MenuItem>
            <MenuItem value={Employment.Internship}>{t('enum:employment_INTERNSHIP')}</MenuItem>
        </BaseField>
    );
};
