import * as Sentry from '@sentry/react';
import { ENVIRONMENT, SENTRY_DSN, VERSION } from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import Setup from './boot/setup';

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        release: VERSION,
    });
}

ReactDOM.render(<Setup />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
