import React from 'react';
import { ButtonBase } from '@material-ui/core/';
import { useStyles } from './arrowButton.style';
import CheckIcon from '@material-ui/icons/Check';

type ArrowButtonProps = {
    target?: string;
    text: string;
    href?: string;
    onClick?: (event: any) => void;
    disableRipple?: boolean;
    hasIcon?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
};

export const ArrowButton: React.FC<ArrowButtonProps> = ({
    style,
    text,
    onClick,
    href,
    disableRipple,
    hasIcon,
    disabled,
    target,
}) => {
    const classes = useStyles();

    return (
        <ButtonBase
            disabled={disabled}
            color="primary"
            disableRipple={disableRipple}
            style={{ ...style }}
            className={`${classes.link} ${hasIcon ? classes.slidingIcon : classes.color}`}
            href={href || ''}
            target={target}
            onClick={onClick}
        >
            {text}
            {hasIcon && <CheckIcon className={classes.icon} />}
        </ButtonBase>
    );
};
