export enum QueryParamKeys {
    Title = 'title',
    CategoryId = 'categoryId',
    CompanyId = 'companyId',
    RegionId = 'regionId',
    Age = 'age',
    Experience = 'experience',
    Salarymin = 'salarymin',
    Salarymax = 'salarymax',
    OrderBy = 'orderby',
    Page = 'page',
}

export const Theme = {
    Dark: 'dark',
    Light: 'light',
};

export enum ViewType {
    List = 'List',
    Grid = 'Grid',
    Table = 'Table',
}

export const UserNetworkStatus = {
    Online: 1,
    Invisible: 2,
};

export const ApolloNetworkStatus = {
    /**
     * The query has never been run before and the query is now currently running. A query will still
     * have this network status even if a partial data result was returned from the cache, but a
     * query was dispatched anyway.
     */
    Loading: 1,
    /**
     * If `setVariables` was called and a query was fired because of that then the network status
     * will be `setVariables` until the result of that query comes back.
     */
    SetVariables: 2,
    /**
     * Indicates that `fetchMore` was called on this query and that the query created is currently in
     * flight.
     */
    FetchMore: 3,
    /**
     * Similar to the `setVariables` network status. It means that `refetch` was called on a query
     * and the refetch request is currently in flight.
     */
    Refetch: 4,
    /**
     * Indicates that a polling query is currently in flight. So for example if you are polling a
     * query every 10 seconds then the network status will switch to `poll` every 10 seconds whenever
     * a poll request has been sent but not resolved.
     */
    Poll: 6,
    /**
     * No request is in flight for this query, and no errors happened. Everything is OK.
     */
    Ready: 7,
    /**
     * No request is in flight for this query, but one or more errors were detected.
     */
    Error: 8,
};

export enum GoogleAnaliticsEvent {
    REGISTER_BUTTON_CLICKED = 'register_button_clicked',
    JOBSEEKER_REGISTERED = 'jobseeker_registered',
    COMPANY_REGISTERED = 'company_registered',
    JOB_VIEWED = 'job_viewed',
    COMPANY_SHARED = 'company_shared',
    BLOG_SHARED = 'blog_shared',
    JOBSEEKER_SHARED = 'jobseeker_shared',
    JOB_SHARED = 'job_shared',
    BLOG_TAG_CLICKED = 'blog_tag_clicked',
    BLOG_SEARCHED = 'blog_searched',
    BLOG_VIEWED = 'blog_viewed',
    JOB_EMAIL_CLICKED = 'job_email_clicked',
    JOB_PHONE_CLICKED = 'job_phone_clicked',
    EASY_APPLY_CLICKED = 'easy_apply_clicked',
    EASY_APPLIED = 'easy_applied',
    COMPANY_LOGGED_IN = 'company_logged_in',
    JOBSEEKER_LOGGED_IN = 'jobseeker_logged_in',
    BROWSEALLJOBS_CLICKED = 'browsealljobs_clicked',
    JOB_SEARCHED = 'job_searched',
    JOB_SEARCH_SAVED = 'job_search_saved',
    JOBSEEKER_DASHBOARD_VIEWED = 'jobseeker_dashboard_viewed',
    JOBSEEKER_APPLICATIONS_VIEWED = 'jobseeker_applications_viewed',
    JOB_FAVORITED = 'jobseeker_favorited',
    JOBSEEKER_FAVORITES_VIEWED = 'jobseeker_favorites_viewed',
    FAVORITE_JOB_REMOVED = 'favorite_job_removed',
    JOBSEEKER_SAVED_SEARCH_VIEWED = 'jobseeker_saved_search_viewed',
    JOBSEEKER_CALENDAR_VIEWED = 'jobseeker_calendar_viewed',
    JOBSEEKER_EVENT_CREATED = 'jobseeker_event_created',
    JOBSEEKER_PROFILE_VIEWED = 'jobseeker_profile_viewed',
    JOBSEEKER_PROFILE_UPDATED = 'jobseeker_profile_updated',
    COMPANY_PAGE_VIEWED = 'company_page_viewed',
    SWITCHED_DARK_MODE = 'switched_dark_mode',
    SWITCHED_LIGHT_MODE = 'switched_light_mode',
    COMPANY_VIEWED_DASHBOARD = 'company_viewed_dashboard',
    COMPANY_VIEWED_JOB_LISTINGS = 'company_viewed_job_listings',
    CLICKED_NEW_JOB = 'clicked_new_job',
    SAVED_DRAFT_JOB = 'saved_draft_job',
    PUBLISHED_JOB = 'published_job',
    VIEWED_CANDIDATES = 'viewed_candidates',
    VIEWED_CANDIDATE = 'viewed_candidate',
    VIEWED_PROCESSES = 'viewed_processes',
    VIEWED_PROCESS = 'viewed_process',
    EDITED_PROCESS = 'edited_process',
    CREATED_PROCESS = 'created_process',
    CREATED_STAGE = 'created_stage',
    COMPANY_VIEWED_CALENDAR = 'company_viewed_calendar',
    COMPANY_CREATED_EVENT = 'company_created_event',
    VIEWED_TEMPLATE = 'viewed_template',
    CREATED_TEMPLATE = 'created_template',
    COMPANY_VIEWED_PROFILE = 'company_viewed_profile',
    COMPANY_UPDATED_PROFILE = 'company_updated_profile',
}
