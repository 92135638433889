import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
    const tagStyle = {
        width: 'fit-content',
        padding: theme.spacing(0.5, 1.5),
        fontSize: '0.75rem',
        borderRadius: 4,
        fontWeight: 600,
    };
    return {
        tags: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            '& span': {
                fontSize: '0.875rem',
                marginRight: '4px',
            },
            '& .MuiSvgIcon-root': {
                fontSize: '0.875rem',
                marginRight: '4px',
            },
            '& li': {
                display: 'flex',
                alignItems: 'center',
                marginRight: 4,
                marginBottom: 4,
                whiteSpace: 'nowrap',
            },
        },
        salaryTag: {
            ...tagStyle,
            color: '#4caf50',
            backgroundColor: 'rgba(76, 175, 80, 0.12)',
        },
        newBadgeTag: {
            ...tagStyle,
            color: '#FF9800',
            backgroundColor: 'rgba(255, 152, 0, 0.12)',
        },
        employmentTag: {
            ...tagStyle,
            color: '#2196F3',
            backgroundColor: 'rgba(33, 150, 243, 0.12)',
        },
        easyApplyTag: {
            ...tagStyle,
            backgroundColor: 'rgba(156,39,176,0.12)',
            color: 'rgba(156,39,176,1)',
            alignItems: 'center',
        },
        acceptedStatusTag: {
            ...tagStyle,
            backgroundColor: 'rgba(52, 190, 130, 0.12)',
            color: 'rgba(52, 190, 130, 1)',
        },
        rejectedStatusTag: {
            ...tagStyle,
            backgroundColor: 'rgba(189, 32, 0, 0.12)',
            color: 'rgba(189, 32, 0, 1)',
        },
        pendingStatusTag: {
            ...tagStyle,
            backgroundColor: 'rgba(255, 202, 3, 0.12)',
            color: 'rgba(255, 202, 3, 1)',
        },
        expiredStatusTag: {
            ...tagStyle,
            backgroundColor: 'rgba(255, 23, 0, 0.12)',
            color: 'rgba(255, 23, 0, 1)',
        },
    };
});
