/* eslint-disable @typescript-eslint/no-empty-function */
import { ConfigStore } from '@mediusoft/matrix-chat';
import { GoogleAnaliticsEvent } from 'enum';
import { LoginPayload, MatrixAuthData } from 'graphql/generated';
import jwtDecode from 'jwt-decode';
import React, { createContext, useContext, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { logAnalyticsEvent } from 'ui-services/analytics.ui-service';
import { isEmpty } from 'utils/validationHelper';
import { sendWebviewMessage } from 'utils/webview-helper';

const USER_KEY = '@Auth/user';
const ACCESS_TOKEN_KEY = '@Auth/accessToken';

export const getAccessToken = (): string | null => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if ([null, undefined, 'null', 'undefined'].includes(accessToken)) {
        return null;
    }

    try {
        return JSON.parse(accessToken as string);
    } catch (_) {
        return accessToken;
    }
};

const isTokenValid = (accessToken?: string | null): boolean => {
    if (isEmpty(accessToken)) {
        return false;
    }
    const decodedData = jwtDecode(accessToken);
    const currentTime = Date.now();
    const expiredTime = decodedData.exp * 1000;
    return currentTime <= expiredTime;
};

export type AuthUserProps =
    | {
          email?: string;
          role?: string;
          firstName?: string;
          lastName?: string;
          jobseekerId?: string;
          id?: string;
          avatar?: { url: string } | null;
          matrixAuth?: MatrixAuthData | null;
      }
    | null
    | undefined;

type AuthContextProps = {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    selectedTab: number;
    selectTab: any;
    isLoggedIn: boolean;
    isEmployer: boolean;
    isJobseeker: boolean;
    isAdmin: boolean;
    user: AuthUserProps;
    email: string;
    setEmail: any;
    saveAuthData: (authData: any) => void;
    saveUserData: (userData: any) => void;
    removeAuthData: () => void;
};

const AuthContext = createContext<AuthContextProps>({
    isModalOpen: false,
    openModal: () => {},
    closeModal: () => {},
    selectedTab: 0,
    selectTab: () => {},
    isLoggedIn: false,
    isEmployer: false,
    isJobseeker: false,
    isAdmin: false,
    user: {},
    email: '',
    setEmail: () => {},
    saveAuthData: () => {},
    saveUserData: () => {},
    removeAuthData: () => {},
});
export const useAuth = (): AuthContextProps => useContext<AuthContextProps>(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [accessToken, setAccessToken] = useLocalStorage<string | null>(ACCESS_TOKEN_KEY, null, {
        raw: true,
    });
    const [user, setUser] = useLocalStorage<AuthUserProps>(USER_KEY, null);
    const [email, setEmail] = useState('');

    const isLoggedIn = isTokenValid(accessToken) && !!user;
    const isEmployer = user?.role === 'employer';
    const isAdmin = user?.role === 'admin';
    const isJobseeker = user?.role === 'jobseeker';

    const saveAuthData = ({ accessToken, matrixAuth, user }: LoginPayload & { user: any }): void => {
        if (user.role === 'employer') {
            logAnalyticsEvent(GoogleAnaliticsEvent.COMPANY_LOGGED_IN);
        }

        if (user.role === 'jobseeker') {
            logAnalyticsEvent(GoogleAnaliticsEvent.JOBSEEKER_LOGGED_IN);
        }
        setAccessToken(accessToken);

        const normalizedUser: AuthUserProps = {
            firstName: user[user.role].firstName,
            lastName: user[user.role].lastName,
            email: user.email,
            role: user.role,
            jobseekerId: user[user.role].id,
            id: user.id,
            avatar: user.avatar,
            matrixAuth,
        };
        setUser(normalizedUser);
        sendWebviewMessage({ type: 'ACCESS_TOKEN_CHANGED', accessToken: accessToken });
    };

    const removeAuthData = (): void => {
        setAccessToken(null);
        setUser(null);
        sendWebviewMessage({ type: 'ACCESS_TOKEN_CHANGED', accessToken: null });
        ConfigStore.clearMatrixAuthData();
    };

    const saveUserData = (userData: any): void => {
        const normalizedUser: AuthUserProps = {
            ...user,
            ...userData,
        };
        setUser(normalizedUser);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTab, selectTab] = useState(0);
    const openModal = (): void => setIsModalOpen(true);
    const closeModal = (): void => {
        setIsModalOpen(false);
        setEmail('');
    };

    return (
        <AuthContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                selectedTab,
                selectTab,
                isLoggedIn,
                isEmployer,
                isJobseeker,
                isAdmin,
                user,
                email,
                setEmail,
                saveAuthData,
                saveUserData,
                removeAuthData,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
