import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PricingPlanItem } from 'components';
import { FeatureCode, PricingPlan, usePricingPlansQuery } from 'graphql/generated';
import React, { useState } from 'react';
import { useStyles } from './pricing-plan-dialog.style';

type PricingPlanDialogProps = {
    open: boolean;
    closeDialog: () => void;
    highlightedFeature: FeatureCode | undefined;
};

export const PricingPlanDialog: React.FC<PricingPlanDialogProps> = ({ open, closeDialog, highlightedFeature }) => {
    const [saving, setSaving] = useState<boolean>(false);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const { data } = usePricingPlansQuery({
        fetchPolicy: 'network-only',
    });

    const pricingPlans = data?.pricingPlans.edges.map(({ node }) => node) || [];

    return (
        <Dialog open={open} fullWidth fullScreen={isMobile} maxWidth="lg" onClose={closeDialog}>
            <DialogTitle className={classes.title} disableTypography>
                <Grid container alignItems="center" justify="space-between">
                    Select a plan
                    <IconButton size="small" onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.content}>
                <Grid container spacing={2}>
                    {pricingPlans.map((plan) => {
                        return (
                            <PricingPlanItem
                                key={plan.id}
                                {...{
                                    plan: plan as PricingPlan,
                                    highlightedFeature,
                                    closeDialog,
                                    saving,
                                    setSaving,
                                }}
                            />
                        );
                    })}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
