import { Box, Card, Grid, Tooltip, Typography, Zoom } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'components';
import { defaultAvatar } from 'config';
import { useAuth } from 'context/AuthContext';
import { ViewType } from 'enum';
import { Jobseeker, JobseekerStatus } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JobseekerTags } from '../jobseeker-tags';
import { useStyle } from './jobseeker-list-item.style';

type JobseekerListItemProps = {
    jobseeker: Jobseeker;
};

export const JobseekerListItem: React.FC<JobseekerListItemProps> = ({ jobseeker }) => {
    const { id, firstName, lastName, avatar, region, status, salaryExpectation, category, keySkills } = jobseeker;

    const { isEmployer, isAdmin } = useAuth();

    const { t } = useTranslation();

    const translatedStatus = status ? t(`enum:jobseekerStatus_${status}`) : '';

    const classes = useStyle();

    const color =
        {
            [JobseekerStatus.Employed]: '#F10000',
            [JobseekerStatus.OpenToOpportunities]: '#47DF00',
        }[status || ''] || '#C4C4C4';

    return (
        <Link to={`/jobseeker/${id}`}>
            <Card className={classes.container} elevation={0}>
                <Grid container className={classes.gridContainer}>
                    <Grid xs={12} container justify="space-between">
                        <Grid xs={12} sm={9} className={classes.profileInfoContainer}>
                            <div className={classes.logoContainer}>
                                <Tooltip TransitionComponent={Zoom} placement="bottom" title={translatedStatus}>
                                    <img
                                        src={avatar?.url || defaultAvatar}
                                        alt="Jobseeker avatar"
                                        style={{
                                            color,
                                        }}
                                    />
                                </Tooltip>
                            </div>

                            <div className={classes.profileInfo}>
                                <Typography variant="h4">{`${firstName} ${lastName}`}</Typography>
                                {region && <Typography variant="h5">{region?.name}</Typography>}
                            </div>
                        </Grid>
                        <Grid xs={12} sm={3} className={classes.salaryContainer}>
                            {salaryExpectation && (isEmployer || isAdmin) && (
                                <Typography variant="h6">{salaryExpectation} AZN</Typography>
                            )}
                        </Grid>
                    </Grid>
                    {category && (
                        <Grid sm={5} xs={12} className={classes.categorySection}>
                            <div>
                                {category && (
                                    <Typography
                                        variant="h6"
                                        className={clsx(classes[`color${category?.order % 7}`], classes.category)}
                                    >
                                        {t(`category:${category?.name}`)}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    )}

                    {keySkills && keySkills?.length > 0 && (
                        <Grid sm={7} xs={12} className={classes.keySkills}>
                            <Box mt={3} flexGrow={1}>
                                <JobseekerTags viewType={ViewType.List} {...{ jobseeker }} />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Link>
    );
};
