import {
    Box,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ListViewIcon from '@material-ui/icons/ViewList';
import GridViewIcon from '@material-ui/icons/ViewModule';
import { usePagination } from 'context/filter/PaginationContext';
import { useSorting } from 'context/filter/SortingContext';
import { ViewType } from 'enum';
import { JobseekerOrderBy } from 'graphql/generated';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './jobseeker-sort-header.style';

type JobseekerSortHeaderProps = {
    viewType?: ViewType;
    setViewType?: (viewType: ViewType) => void;
    totalCount: number;
};

export const JobseekerSortHeader: React.FC<JobseekerSortHeaderProps> = ({ viewType, setViewType, totalCount }) => {
    const classes = useStyle();
    const theme = useTheme();
    const { page, setPage } = usePagination();

    const { t } = useTranslation();
    const { orderBy, setOrderBy, initOrderBy } = useSorting<JobseekerOrderBy>();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        initOrderBy();
    }, []);

    const toggleViewType = (): void => {
        if (setViewType) {
            if (viewType === ViewType.List) {
                setViewType(ViewType.Grid);
            } else {
                setViewType(ViewType.List);
            }
        }
    };

    return (
        <Grid className={classes.root} item>
            <Box className={classes.container}>
                <Box display="flex">
                    <Typography variant="h6" style={{ marginRight: 4 }}>
                        {totalCount}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                        {t('jobseekerList:found')}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <FormControl>
                        <Select
                            className={classes.select}
                            value={orderBy || JobseekerOrderBy.CreatedAtDesc}
                            variant="outlined"
                            onChange={(event: any): void => {
                                setOrderBy(event.target.value || '');
                                if (page) {
                                    setPage(1);
                                }
                            }}
                        >
                            <MenuItem value={JobseekerOrderBy.CreatedAtDesc}>
                                {t('filter:sortBy_newest_first')}
                            </MenuItem>
                            <MenuItem value={JobseekerOrderBy.CreatedAtAsc}>{t('filter:sortBy_oldest_first')}</MenuItem>
                        </Select>
                    </FormControl>
                    {!isMobile && viewType && (
                        <Tooltip placement="bottom" arrow title={t('filter:toggleViewType') || ''}>
                            <IconButton aria-label="List view" color="primary" onClick={toggleViewType}>
                                {viewType === ViewType.List ? <ListViewIcon /> : <GridViewIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};
