import { Link, LinkProps } from 'components/link/Link';
import { usePricingPlan } from 'context/PricingPlanContext';
import { FeatureCode, PurchasedPlan, useCurrentPlanQuery } from 'graphql/generated';
import React, { useState } from 'react';

type FeatureLinkProps = LinkProps & { link?: boolean; featureCode: FeatureCode };

export const FeatureLink: React.FC<FeatureLinkProps> = ({ featureCode, children, ...props }) => {
    const { isFeatureEnabled, showPricingDialog } = usePricingPlan();
    const [enabled, setEnabled] = useState(false);

    useCurrentPlanQuery({
        onCompleted({ currentPlan }) {
            const enabled = isFeatureEnabled(currentPlan as PurchasedPlan, featureCode);
            setEnabled(enabled);
        },
    });

    return enabled ? (
        <Link {...props}>{children}</Link>
    ) : (
        <div onClick={() => showPricingDialog(featureCode)}>
            <Link disabled={!enabled} {...props}>
                {children}
            </Link>
        </div>
    );
};
