import { useScrollTrigger, Zoom } from '@material-ui/core';
import React from 'react';
import { useStyles } from './scroll-top.style';

export const ScrollTop: React.FC = ({ children }) => {
    const classes = useStyles();
    const scrollAnchor = window;
    const trigger = useScrollTrigger({ target: scrollAnchor, disableHysteresis: true, threshold: 100 });

    const handleClick = () => {
        if (scrollAnchor) {
            scrollAnchor.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.container}>
                {children}
            </div>
        </Zoom>
    );
};
