import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { useStyle } from './skillsField.style';

const data = [
    { title: 'HTML' },
    { title: 'CSS' },
    { title: 'Bootstrap' },
    { title: 'JQuery' },
    { title: 'Git' },
    { title: 'Javascript' },
    { title: 'React' },
    { title: 'React Native' },
    { title: 'Angular' },
    { title: 'Vue' },
];

type SkillsFieldProps = {
    label?: string;
    name: string;
    placeHolder?: string;
    borderless?: boolean;
};

export const SkillsField: React.FC<SkillsFieldProps> = ({ label, name, placeHolder, borderless }) => {
    const classes = useStyle();
    return (
        <Field name={name}>
            {({
                field: { name: fieldName, value },
                form: { setFieldValue, touched, errors },
            }: FieldProps): React.ReactNode => (
                <Autocomplete
                    className={clsx(classes.autoComplete, borderless && classes.borderless)}
                    freeSolo
                    multiple
                    value={value}
                    onChange={(_, newValue: string[]): void => {
                        setFieldValue(fieldName, newValue);
                    }}
                    options={data.map((option) => option.title)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={touched[fieldName] && !!errors[fieldName]}
                            helperText={errors[fieldName]}
                            label={label}
                            variant="outlined"
                            placeholder={placeHolder}
                        />
                    )}
                />
            )}
        </Field>
    );
};
