import { Box, IconButton, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from 'assets/social-icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/social-icons/linkedin.svg';
import { ReactComponent as TelegramIcon } from 'assets/social-icons/telegram.svg';
import { ReactComponent as TwitterIcon } from 'assets/social-icons/twitter.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/social-icons/whatsapp.svg';
import { socialShareLink } from 'config';
import { GoogleAnaliticsEvent } from 'enum';
import React from 'react';
import { logAnalyticsEvent } from 'ui-services/analytics.ui-service';

type SocialShareButtonsProps = {
    url: string;
    pageType: 'jobseeker' | 'job' | 'company' | 'blog';
};

export const SocialShareButtons: React.FC<SocialShareButtonsProps> = ({ url, pageType, children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const onSocialShareClick = () => {
        switch (pageType) {
            case 'blog':
                logAnalyticsEvent(GoogleAnaliticsEvent.BLOG_SHARED);
                break;
            case 'jobseeker':
                logAnalyticsEvent(GoogleAnaliticsEvent.JOBSEEKER_SHARED);
                break;
            case 'company':
                logAnalyticsEvent(GoogleAnaliticsEvent.COMPANY_SHARED);
                break;
            case 'job':
                logAnalyticsEvent(GoogleAnaliticsEvent.JOB_SHARED);
                break;
        }
    };

    return (
        <Box display="flex" width="100%" justifyContent="space-between">
            <a
                onClick={onSocialShareClick}
                rel="noreferrer noopener"
                target="_blank"
                href={socialShareLink.facebook(url)}
            >
                <IconButton>
                    <SvgIcon component={FacebookIcon} />
                </IconButton>
            </a>
            <a
                onClick={onSocialShareClick}
                rel="noreferrer noopener"
                target="_blank"
                href={socialShareLink.twitter(url)}
            >
                <IconButton>
                    <SvgIcon component={TwitterIcon} />
                </IconButton>
            </a>
            <a
                onClick={onSocialShareClick}
                rel="noreferrer noopener"
                target="_blank"
                href={socialShareLink.linkedin(url)}
            >
                <IconButton>
                    <SvgIcon component={LinkedinIcon} />
                </IconButton>
            </a>
            <a
                onClick={onSocialShareClick}
                rel="noreferrer noopener"
                target="_blank"
                href={socialShareLink.telegram(url)}
            >
                <IconButton>
                    <SvgIcon component={TelegramIcon} />
                </IconButton>
            </a>
            <a
                onClick={onSocialShareClick}
                rel="noreferrer noopener"
                target="_blank"
                href={isMobile ? socialShareLink.whatsappMobile(url) : socialShareLink.whatsappWeb(url)}
            >
                <IconButton>
                    <SvgIcon component={WhatsAppIcon} />
                </IconButton>
            </a>
            {children}
        </Box>
    );
};
