import TextField from '@material-ui/core/TextField';
import React, { ReactNode } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { useStyles } from './autocomplete.style';
import { useTranslation } from 'react-i18next';

type AutoCompleteInputProps = {
    loading: boolean;
    options: any[];
    onChange: any;
    onInputChange?: any;
    touched: any;
    error: any;
    value: any;
    getOptionSelected: any;
    getOptionLabel: any;
    label?: string;
};

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
    loading,
    options,
    onChange,
    onInputChange,
    touched,
    error,
    value,
    getOptionSelected,
    getOptionLabel,
    label,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Autocomplete
            className={classes.root}
            classes={{
                inputRoot: classes.inputRoot,
                input: classes.inputInput,
            }}
            options={options}
            loading={loading}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            onInputChange={onInputChange}
            value={value}
            noOptionsText={t('noOptions')}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                <TextField label={label} size="small" {...params} error={touched && error} variant="outlined" />
            )}
        />
    );
};
