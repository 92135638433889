import React from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import {
    GA_API_KEY,
    GA_APP_ID,
    GA_AUTH_DOMAIN,
    GA_MEASURMENT_ID,
    GA_MESSAGING_SENDER_ID,
    GA_PROJECT_ID,
    GA_STORAGE_BUCKET,
} from 'config';

const firebaseConfig = {
    apiKey: GA_API_KEY,
    authDomain: GA_AUTH_DOMAIN,
    projectId: GA_PROJECT_ID,
    storageBucket: GA_STORAGE_BUCKET,
    messagingSenderId: GA_MESSAGING_SENDER_ID,
    appId: GA_APP_ID,
    measurementId: GA_MEASURMENT_ID,
};

if (GA_API_KEY) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}

// From: https://github.com/react-ga/react-ga/issues/122#issuecomment-320436578
export class Analytics extends React.Component {
    constructor(props) {
        super(props);

        this.sendPageChange(props.location.pathname, props.location.search);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location.pathname !== prevProps.location.pathname || location.search !== prevProps.location.search) {
            this.sendPageChange(location.pathname, location.search);
        }
    }

    sendPageChange = (pathname, search = '') => {
        const page = pathname + search;

        if (GA_API_KEY) {
            firebase.analytics().setCurrentScreen(page);
        }
    };

    render() {
        return null;
    }
}
