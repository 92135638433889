import Grid, { GridProps } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode } from 'react';
import { useStyle } from './page-header.style';

interface PageHeaderProps extends Omit<GridProps, 'title'> {
    title: string | ReactNode;
    children?: ReactNode;
    disableTypography?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, children, disableTypography, ...props }) => {
    const classes = useStyle();

    return (
        <Grid container className={classes.container} justify="space-between" {...props}>
            {disableTypography ? (
                title
            ) : (
                <Typography className={classes.title} variant="h3">
                    {title}
                </Typography>
            )}

            <Grid className={classes.rightContainer}>{children}</Grid>
        </Grid>
    );
};
