import { Slider } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React from 'react';

type SalaryFieldProps = {
    name: string;
};

export const SalaryField: React.FC<SalaryFieldProps> = ({ name }) => {
    return (
        <Field name={name}>
            {({ field: { name: fieldName, value: salary }, form: { setFieldValue } }: FieldProps): React.ReactNode => {
                const value = [salary?.from || 0, salary?.to || 0];

                const onChange = (_: any, newValue: number | number[]) => {
                    const newSalary = Array.isArray(newValue)
                        ? { from: newValue[0], to: newValue[1] }
                        : { from: newValue, to: newValue };
                    setFieldValue(fieldName, newSalary);
                };

                return (
                    <Slider
                        value={value}
                        step={100}
                        min={0}
                        max={10000}
                        onChange={onChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                );
            }}
        </Field>
    );
};
