import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { usePagination } from 'context/filter/PaginationContext';
import { useSorting } from 'context/filter/SortingContext';
import { JobOrderBy } from 'graphql/generated';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './header.style';

type JobsHeaderParams = {
    totalCount: number;
};

export const JobHeader: React.FC<JobsHeaderParams> = ({ totalCount }) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const { page, setPage } = usePagination();
    const { orderBy, setOrderBy, initOrderBy } = useSorting<JobOrderBy>();

    useEffect(() => {
        initOrderBy();
    }, []);

    return (
        <Grid className={classes.root} item>
            <Box className={classes.container}>
                <Box display="flex">
                    <Typography variant="h6" style={{ marginRight: 4 }}>
                        {totalCount}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                        {t('jobList:found')}
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                    <FormControl>
                        <Select
                            className={classes.select}
                            value={orderBy || JobOrderBy.PublishedAtDesc}
                            variant="outlined"
                            onChange={(event: any): void => {
                                setOrderBy(event.target.value || '');
                                if (page) {
                                    setPage(1);
                                }
                            }}
                        >
                            <MenuItem value={JobOrderBy.PublishedAtDesc}>{t('filter:sortBy_newest_first')}</MenuItem>
                            <MenuItem value={JobOrderBy.PublishedAtAsc}>{t('filter:sortBy_oldest_first')}</MenuItem>
                            <MenuItem value={JobOrderBy.SalaryAsc}>{t('filter:sortBy_salaryMin_asc')}</MenuItem>
                            <MenuItem value={JobOrderBy.SalaryDesc}>{t('filter:sortBy_salaryMax_desc')}</MenuItem>
                            <MenuItem value={JobOrderBy.ViewCountAsc}>{t('filter:sortBy_viewCount_asc')}</MenuItem>
                            <MenuItem value={JobOrderBy.ViewCountDesc}>{t('filter:sortBy_viewCount_desc')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Grid>
    );
};
