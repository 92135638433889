import { makeStyles, Switch, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type EasyApplyFieldProps = {
    setValue: any;
    value: boolean | undefined;
};

export const useStyle = makeStyles(() => {
    return {
        title: {
            fontWeight: 600,
        },
        field: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    };
});

export const EasyApplyField: React.FC<EasyApplyFieldProps> = ({ setValue, value }) => {
    const classes = useStyle();
    const { t } = useTranslation();

    return (
        <div className={classes.field}>
            <Typography className={classes.title} variant="h6">
                {t('filter:onlyEasyApply')}
            </Typography>
            <Switch
                checked={value}
                onChange={(e) => setValue('onlyEasyApply', e.target.checked)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                color="primary"
            />
        </div>
    );
};
