import { PaletteType } from '@material-ui/core';
import { SettingType, useSelectedSettingsLazyQuery, useUpdateUserSettingMutation } from 'graphql/generated';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { LANGUAGE_KEY } from 'utils/localStorage';
import { sendWebviewMessage } from 'utils/webview-helper';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';
import { logAnalyticsEvent } from 'ui-services/analytics.ui-service';
import { GoogleAnaliticsEvent } from 'enum';

type SettingsContextProps = {
    language?: string;
    setLanguage: React.Dispatch<React.SetStateAction<string | undefined>>;
    isDarkMode: boolean;
    theme?: PaletteType;
    toggleTheme: () => void;
    refetchSettings: () => void;
};

const SettingsContext = createContext<SettingsContextProps>({
    language: 'az',
    isDarkMode: false,
    theme: 'light',
    toggleTheme: () => {},
    setLanguage: () => {},
    refetchSettings: () => {},
});
export const useSettings = (): SettingsContextProps => useContext<SettingsContextProps>(SettingsContext);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [language, setLanguage] = useLocalStorage(LANGUAGE_KEY, 'az', { raw: true });
    const [theme, setTheme] = useLocalStorage<PaletteType>('@Settings/theme', 'light', { raw: true });
    const { isLoggedIn } = useAuth();
    const { i18n } = useTranslation();

    const [loadSettings, { data, refetch }] = useSelectedSettingsLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [updateSetting] = useUpdateUserSettingMutation();

    const settings = data?.selectedSettings.edges.map(({ node }) => node) || [];

    const isDarkMode = theme === 'dark';
    const toggleTheme = (): void => setTheme(isDarkMode ? 'light' : 'dark');

    const refetchSettings = () => {
        refetch?.();
    };

    useEffect(() => {
        if (isLoggedIn) {
            loadSettings();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        const theme = settings.find((setting) => setting.type === SettingType.Theme);
        if (theme) {
            setTheme((theme.value as PaletteType) || 'light');
        }
        const language = settings.find((setting) => setting.type === SettingType.Language);
        if (language) {
            setLanguage(language.value || 'az');
            i18n.changeLanguage(language.value || 'az');
        }
    }, [data]);

    useEffect(() => {
        sendWebviewMessage({ type: 'THEME_CHANGED', value: theme });
        const themeSetting = settings.find((setting) => setting.type === SettingType.Theme);
        if (themeSetting && theme && isLoggedIn) {
            updateSetting({
                variables: {
                    input: {
                        settingId: themeSetting.settingId,
                        value: theme,
                    },
                },
            });
        }
        if (theme === 'light') {
            logAnalyticsEvent(GoogleAnaliticsEvent.SWITCHED_LIGHT_MODE);
        } else {
            logAnalyticsEvent(GoogleAnaliticsEvent.SWITCHED_DARK_MODE);
        }
    }, [theme]);

    useEffect(() => {
        const languageSetting = settings.find((setting) => setting.type === SettingType.Language);
        if (languageSetting && language && isLoggedIn) {
            updateSetting({
                variables: {
                    input: {
                        settingId: languageSetting.settingId,
                        value: language,
                    },
                },
            });
        }
    }, [language]);

    return (
        <SettingsContext.Provider value={{ language, setLanguage, isDarkMode, theme, toggleTheme, refetchSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};
