/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';
import { extractPageFromQueryString, setSearchParams } from 'utils/browser-utils';

type PaginationContextProps = {
    page?: number;
    pageCount?: number;
    initPage: () => void;
    setPage: (page: number) => void;
    setPageCount: (page: number) => void;
};

const PaginationContext = createContext<PaginationContextProps>({
    initPage: () => {},
    setPage: () => {},
    setPageCount: () => {},
});

export const usePagination = (): PaginationContextProps => useContext(PaginationContext);

export const PaginationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [pageValue, setPageValue] = useState<number | undefined>();
    const [pageCount, setPageCount] = useState<number | undefined>();

    const setPage = (page: number): void => {
        setPageValue(page);
        setSearchParams({ page });
    };

    const initPage = (): void => {
        const page = extractPageFromQueryString();
        setPageValue(page);
    };

    return (
        <PaginationContext.Provider value={{ page: pageValue, initPage, setPage, pageCount, setPageCount }}>
            {children}
        </PaginationContext.Provider>
    );
};
