/* eslint-disable react/display-name */
import React from 'react';
import Icon from '@mdi/react';
import {
    mdiAccountGroup,
    mdiAirport,
    mdiBookOpenPageVariant,
    mdiBroom,
    mdiBrush,
    mdiBullhorn,
    mdiCashMultiple,
    mdiCctv,
    mdiCrane,
    mdiFactory,
    mdiFlaskOutline,
    mdiLaptopWindows,
    mdiMathCompass,
    mdiPickaxe,
    mdiSale,
    mdiScaleBalance,
    mdiSchool,
    mdiShape,
    mdiSilverwareForkKnife,
    mdiStethoscope,
    mdiTaxi,
    mdiTie,
} from '@mdi/js';

type IconProps = {
    size: number | string | null;
    color: string | null;
};

const renderIcon =
    (icon: string) =>
    (iconProps: IconProps): React.ReactElement =>
        <Icon path={icon} {...iconProps} />;

export const categoryIcons = {
    'finance-accounting': renderIcon(mdiCashMultiple),
    marketing: renderIcon(mdiBullhorn),
    technology: renderIcon(mdiLaptopWindows),
    'administrative-customersupport': renderIcon(mdiAccountGroup),
    'sales-retail': renderIcon(mdiSale),
    'media-arts-design': renderIcon(mdiBrush),
    legal: renderIcon(mdiScaleBalance),
    'education-instruction': renderIcon(mdiBookOpenPageVariant),
    'manufacturing-utilities': renderIcon(mdiFactory),
    'construction-extraction': renderIcon(mdiCrane),
    'farming-fishing-forestry': renderIcon(mdiPickaxe),
    'architecture-engineering': renderIcon(mdiMathCompass),
    'science-research': renderIcon(mdiFlaskOutline),
    'cleaning-groundsmaintenance': renderIcon(mdiBroom),
    'food-beverage': renderIcon(mdiSilverwareForkKnife),
    transportation: renderIcon(mdiTaxi),
    'personal-service': renderIcon(mdiTie),
    'travel-attractions-events': renderIcon(mdiAirport),
    'protective-security': renderIcon(mdiCctv),
    healthcare: renderIcon(mdiStethoscope),
    'student-jobs': renderIcon(mdiSchool),
    other: renderIcon(mdiShape),
};
