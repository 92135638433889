import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    container: {
        boxShadow: '0 2px 6px rgba(0,0,0,.12)',
    },
    dialogContainer: {
        boxShadow: 'none',
    },
    detail: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0px !important',
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            [theme.breakpoints.down('sm')]: {
                padding: '12px 14px',
                fontSize: '14.5px',
            },
        },
    },
    dialogDetail: {
        borderTop: 0,
    },
    textField: {
        margin: 0,
    },
}));
