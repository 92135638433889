import { CompanyFilter } from 'context/CompanyFilterContext';
import { JobFilter } from 'context/JobFilterContext';
import { JobDetailQuery, Salary } from 'graphql/generated';
import { getSearchParams } from 'utils/browser-utils';
import { fromISODate, getDateText } from 'utils/dateTimeHelper';
import { isEmpty } from 'utils/validationHelper';
import { JobDetailProps } from 'views/job-detail/job-detail';

export const getSalaryText = (salary: Salary | null | undefined, emptyChar?: string): string | null => {
    const salaryParts: number[] = [];
    if (!!salary?.from) {
        salaryParts.push(salary?.from);
    }

    if (!!salary?.to && salary?.to !== salary?.from) {
        salaryParts.push(salary.to);
    }

    if (salaryParts.length > 0) {
        return `${salaryParts.join(' - ')} AZN`;
    }

    return emptyChar || null;
};

export const normalizeJob = (jobDetail: JobDetailQuery): JobDetailProps => {
    const job = jobDetail.job;
    const posted = fromISODate(job.createdAt);
    const deadlineText = fromISODate(job.expiresAt).toFormat('dd.MM.yyyy');
    const postedDateText = getDateText(posted.toISO());
    const salaryText = getSalaryText(job.salary);

    return { ...job, postedDateText, deadlineText, salaryText } as JobDetailProps;
};

export const normalizeJobs = (jobs: any): any => {
    return jobs.map((job: any) => {
        const postedDaysAgo = Math.floor(Math.abs(fromISODate(job.posted).diffNow('days').days));

        return {
            ...job,
            PostedDaysAgo: postedDaysAgo,
        };
    });
};

export const extractFilterFromQS = (): JobFilter => {
    const { salaryMin, salaryMax, page, orderBy, ...searchParams } = getSearchParams();
    const filter: JobFilter = searchParams as any;

    if (!isEmpty(salaryMin) || !isEmpty(salaryMax)) {
        filter.salary = {
            from: isEmpty(salaryMin) ? 0 : Number(salaryMin),
            to: isEmpty(salaryMax) ? 0 : Number(salaryMax),
        };
    }

    return filter;
};

export const extractCompanyFilterFromQS = (): CompanyFilter => {
    const { orderBy, page, ...searchParams } = getSearchParams();
    const filter: CompanyFilter = searchParams as any;

    return filter;
};
