import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            ...theme.typography.h6,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(0.5, 1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: 'fit-content',
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});
