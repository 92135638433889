import { Box, Button, Grid, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import { Spinner } from 'components';
import { RouteLeavingGuard } from 'components/route-leaving-guard';
import { useNotifications } from 'context/NotificationsContext';
import { useSettings } from 'context/SettingsContext';
import { Formik } from 'formik';
import {
    SettingDataType,
    SettingScope,
    SettingType,
    UpdateUserSettingInput,
    UpdateUserSettingsInput,
    useSelectedSettingsQuery,
    useSettingGroupsQuery,
    useUpdateUserSettingsMutation,
} from 'graphql/generated';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import browserHistory from 'utils/browser-utils';
import { useStyles } from './settings.style';

type SettingsProps = {
    scopes: SettingScope[];
};

export const Settings: React.FC<SettingsProps> = ({ scopes }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { notify } = useNotifications();
    const { language, refetchSettings } = useSettings();

    const languageRef = useRef<string>();

    const { data, loading } = useSettingGroupsQuery({ fetchPolicy: 'cache-and-network' });
    const { data: selectedSettingsData, loading: selectedSettingsLoading } = useSelectedSettingsQuery({
        fetchPolicy: 'network-only',
    });

    const [updateUserSettings] = useUpdateUserSettingsMutation({
        onCompleted() {
            notify({
                type: 'success',
                message: t('setting:applySuccessful'),
            });
            refetchSettings();
        },
    });
    const revertLanguage = async () => {
        await i18n.changeLanguage(languageRef.current || 'az');
    };

    useEffect(() => {
        return () => {
            revertLanguage();
        };
    }, []);

    useEffect(() => {
        languageRef.current = language;
    }, [language]);

    if (loading || selectedSettingsLoading) {
        return <Spinner />;
    }

    const settingGroups = data?.settingGroups.edges.map(({ node }) => node) || [];
    const selectedSettings = selectedSettingsData?.selectedSettings.edges.map(({ node }) => node) || [];

    const initialValues: UpdateUserSettingsInput = {
        settings: selectedSettings.map((setting) => ({ settingId: setting.settingId, value: setting.value || '' })),
    };

    const handleChange = (setting: UpdateUserSettingInput, values: UpdateUserSettingsInput, setFieldValue) => {
        const existingSetting = values.settings.find((s) => s.settingId === setting.settingId);
        if (existingSetting) {
            const filteredSettings = values.settings.filter((s) => s.settingId !== setting.settingId);
            setFieldValue('settings', [...filteredSettings, setting]);
        } else {
            setFieldValue('settings', [...values.settings, setting]);
        }
    };

    const onSubmit = (input: UpdateUserSettingsInput) => {
        updateUserSettings({ variables: { input } });
    };

    return (
        <Box>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ submitForm, values, setFieldValue, dirty }) => (
                    <Grid container spacing={2}>
                        <RouteLeavingGuard
                            when={dirty}
                            navigate={(path) => browserHistory.push(path)}
                            shouldBlockNavigation={() => {
                                if (dirty) {
                                    return true;
                                }
                                return false;
                            }}
                        />
                        {settingGroups.map((settingGroup) => {
                            const settings = settingGroup.settings.edges
                                .map(({ node }) => node)
                                .filter((setting) => setting.scopes?.every((scope) => scopes.includes(scope)));
                            return (
                                <Grid key={settingGroup.id} item xs={12}>
                                    <Box>
                                        <Typography className={classes.title} variant="h5">
                                            {t(`setting:${settingGroup.name}`)}
                                        </Typography>
                                        <Box p={1}>
                                            {settings.map((setting) => {
                                                const settingValue = selectedSettings.find(
                                                    (selectedSetting) => selectedSetting.settingId === setting.id,
                                                )?.value;

                                                if (setting.dataType === SettingDataType.String) {
                                                    return (
                                                        <Box key={setting.id} className={classes.root}>
                                                            <Typography
                                                                color="textSecondary"
                                                                style={{ wordBreak: 'break-word' }}
                                                            >
                                                                {t(`setting:${setting.type}`)}
                                                            </Typography>
                                                            <Select
                                                                className={classes.selects}
                                                                defaultValue={settingValue}
                                                                variant="outlined"
                                                                margin="dense"
                                                                onChange={(e) => {
                                                                    const value = e.target.value as string;

                                                                    if (setting.type === SettingType.Language) {
                                                                        i18n.changeLanguage(value);
                                                                    }

                                                                    if (value) {
                                                                        handleChange(
                                                                            {
                                                                                settingId: setting.id,
                                                                                value,
                                                                            },
                                                                            values,
                                                                            setFieldValue,
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {setting.allowedValues?.map((allowedValue) => (
                                                                    <MenuItem
                                                                        key={allowedValue.value}
                                                                        value={allowedValue.value}
                                                                    >
                                                                        {t(`setting:${allowedValue.label}`)}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Box>
                                                    );
                                                }

                                                if (setting.dataType === SettingDataType.Boolean) {
                                                    return (
                                                        <Box key={setting.id} className={classes.root}>
                                                            <Typography
                                                                color="textSecondary"
                                                                style={{ wordBreak: 'break-word' }}
                                                            >
                                                                {t(`setting:${setting.type}`)}
                                                            </Typography>
                                                            <Switch
                                                                color="primary"
                                                                defaultChecked={settingValue === 'true'}
                                                                onChange={(_, checked) =>
                                                                    handleChange(
                                                                        {
                                                                            settingId: setting.id,
                                                                            value: checked.toString(),
                                                                        },
                                                                        values,
                                                                        setFieldValue,
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    );
                                                }
                                            })}
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        })}
                        <Grid item container justify="flex-end" xs={12}>
                            <Button variant="contained" color="primary" style={{ padding: 10 }} onClick={submitForm}>
                                {t('setting:settingSave')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Box>
    );
};
