import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, Observable, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ChatProvider, useChatState } from '@mediusoft/matrix-chat';
import { createUploadLink } from 'apollo-upload-client';
import { API_URL } from 'config';
import { AuthProvider, getAccessToken, useAuth } from 'context/AuthContext';
import { SettingsProvider } from 'context/SettingsContext';
import 'i18n';
import React, { useEffect } from 'react';
import { handleNotification } from 'ui-services/notification.ui-service';
import AppComponent from './App';
import { InitializeMatrix } from './initialize-matrix';

const cache = new InMemoryCache({});

const request = (operation: Operation): void => {
    if (getAccessToken()) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${getAccessToken()}`,
            },
        });
    }
};

const requestLink = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            let handle: any;
            Promise.resolve(operation)
                .then((oper) => request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return (): void => {
                if (handle) handle.unsubscribe();
            };
        }),
);

const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ networkError, graphQLErrors }) => {
            if (graphQLErrors && graphQLErrors.length > 0) {
                // eslint-disable-next-line no-console
                console.group('GraphQL Errors');
                graphQLErrors.forEach((error) => {
                    console.error(error);
                });
                // eslint-disable-next-line no-console
                console.groupEnd();
            }

            if (networkError) {
                console.warn('Network Error');
                console.error(networkError);
            }
        }),
        requestLink,
        createUploadLink({ uri: `${API_URL}/graphql` }),
    ]),
    cache,
});

const LoadMatrix = () => {
    const { chatIsReady } = useChatState();
    if (chatIsReady) {
        return <InitializeMatrix />;
    } else {
        return <></>;
    }
};

const App = () => {
    const { user } = useAuth();
    const matrixAuth = user?.matrixAuth;

    if (matrixAuth) {
        return (
            <ChatProvider baseUrl="https://synapse.staffy.az" matrixAuth={matrixAuth}>
                <AppComponent />
                <LoadMatrix />
            </ChatProvider>
        );
    }
    return <AppComponent />;
};

const Setup: React.FC = () => {
    useEffect(() => {
        (document as any).handleNotification = handleNotification;
    }, []);

    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <SettingsProvider>
                    <App />
                </SettingsProvider>
            </AuthProvider>
        </ApolloProvider>
    );
};

export default Setup;
