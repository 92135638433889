import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyle = makeStyles((theme) => {
    const isLightMode = theme.palette.type === Theme.Light;

    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            flexWrap: 'wrap',
            padding: theme.spacing(2.5),
            backgroundColor: isLightMode ? 'rgb(255,255,255)' : 'rgb(44, 44, 44)',
            transition: '.3s box-shadow',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: theme.shadows[2],
            },
        },

        color0: {
            backgroundColor: 'rgba(244, 110, 110, 0.08)',
            color: 'rgb(244, 110, 110)',
        },
        color1: {
            backgroundColor: 'rgb(73, 163, 246, 0.08)',
            color: 'rgb(73, 163, 246)',
        },
        color2: {
            backgroundColor: 'rgb(73, 204, 246, 0.08)',
            color: 'rgb(73, 204, 246)',
        },
        color3: {
            backgroundColor: 'rgb(244, 173, 73, 0.08)',
            color: 'rgb(244, 173, 73)',
        },
        color4: {
            backgroundColor: 'rgb(182, 127, 253, 0.08)',
            color: 'rgb(182, 127, 253)',
        },
        color5: {
            backgroundColor: 'rgb(201, 12, 215, 0.08)',
            color: 'rgb(201, 12, 215)',
        },
        color6: {
            backgroundColor: 'rgb(14, 167, 111, 0.08)',
            color: 'rgb(14, 167, 111)',
        },
        color7: {
            backgroundColor: 'rgb(52, 130, 210, 0.08)',
            color: 'rgb(52, 130, 210)',
        },

        logoContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1.5),
            flexBasis: 'unset',
            overflow: 'hidden',
            width: 65,
            height: 65,
            minWidth: 65,
            minHeight: 65,
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                border: '4px solid',
                borderRadius: '50%',
                overflow: 'hidden',
            },
        },
        gridContainer: {
            width: '100%',
            height: '100%',
        },

        profileInfo: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(1.6),
            '& div': {
                paddingLeft: 5,
            },
            '& h4': {
                fontSize: 19,
            },
            '& h5': {
                color: 'rgb(112, 112, 112)',
            },
        },

        categorySalarySection: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: theme.spacing(2),
            minHeight: 50,
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'row',
            },
        },

        categoryContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
        },

        category: {
            height: 'max-content',
            borderRadius: theme.spacing(0.5),
            textAlign: 'center',
            padding: '5px 8px',
            wordBreak: 'break-all',
        },

        salaryContainer: {
            display: 'flex',
            justifyContent: 'flex-end',

            '& h6': {
                display: 'flex',
                alignItems: 'center',
                padding: '3px 5px',
                marginLeft: 5,
                fontSize: 16,
                color: '#32C200',
                paddingLeft: 0,
            },

            [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-start',
                paddingTop: 10,
            },
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'flex-end',
                paddingTop: 0,
            },
        },

        keySkills: {
            display: 'flex',
            alignItems: 'end',
        },

        profileInfoContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
    };
});
