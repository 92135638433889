import defaultCompanyLogo from 'assets/default_company_logo.png';
import logoGray from 'assets/staffy-logo-white.svg';
import logoBlue from 'assets/staffy-logo-black.svg';

declare global {
    interface Window {
        _env_: any;
    }
}

export const socialShareLink = {
    facebook: (url: string): string => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    twitter: (url: string): string => `http://twitter.com/share?url=${encodeURIComponent(url)}`,
    linkedin: (url: string): string => `https://www.linkedin.com/sharing/share-offsite?url=${encodeURIComponent(url)}`,
    telegram: (url: string): string => `https://t.me/share/url?url=${encodeURIComponent(url)}`,
    whatsappWeb: (url: string): string => `https://web.whatsapp.com/send?text=${encodeURIComponent(url)}`,
    whatsappMobile: (url: string): string => `https://wa.me/?text=${encodeURIComponent(url)}`,
};

const env = {
    ...process.env,
    ...window._env_,
};

export const DEFAULT_PAGE_SIZE = 20;

export const VERSION = env.REACT_APP_VERSION;
export const ENVIRONMENT = env.REACT_APP_ENVIRONMENT;
export const API_URL = env.REACT_APP_API_URL;
export const GA_API_KEY = env.REACT_APP_GA_API_KEY;
export const GA_AUTH_DOMAIN = env.REACT_APP_GA_AUTH_DOMAIN;
export const GA_PROJECT_ID = env.REACT_APP_GA_PROJECT_ID;
export const GA_STORAGE_BUCKET = env.REACT_APP_GA_STORAGE_BUCKET;
export const GA_MESSAGING_SENDER_ID = env.REACT_APP_GA_MESSAGING_SENDER_ID;
export const GA_APP_ID = env.REACT_APP_GA_APP_ID;
export const GA_MEASURMENT_ID = env.REACT_APP_GA_MEASURMENT_ID;
export const GOOGLE_CLIENT_ID = env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const FB_CLIENT_ID = env.REACT_APP_FB_CLIENT_ID;
export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN;

export const mobileSizes = ['xs', 'sm'];
export const imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif'];
export const headerHeight = 80;
export const defaultAvatar = 'https://s3.mediusoft.com/assets/staffy/blank_avatar.png';

export { defaultCompanyLogo, logoGray as logoTextWhite, logoBlue as logoTextBlack };
