import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Typography } from '@material-ui/core';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { useStyles } from './route-leaving-guard.style';

interface RouteLeavingGuardProps {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
}
export const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({ when, navigate, shouldBlockNavigation }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };
    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);
    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Dialog open={modalVisible}>
                <DialogTitle disableTypography>{t('routeLeavingGuard:title')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('routeLeavingGuard:content')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={closeModal}>
                        {t('routeLeavingGuard:stayButton')}
                    </Button>
                    <Button variant="contained" className={classes.leave} onClick={handleConfirmNavigationClick}>
                        {t('routeLeavingGuard:leaveButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
