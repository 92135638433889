import React from 'react';
import { Helmet } from 'react-helmet';

type OpenGraphTagsProps = {
    url: string;
    title: string;
    description: string;
    imageUrl?: string;
};

export const OpenGraphTags: React.FC<OpenGraphTagsProps> = ({ url, title, description, imageUrl }) => {
    return (
        <Helmet>
            <meta property="og:title" content={title} />
            <meta name="description" property="og:description" content={description} />
            <meta name="image" property="og:image" content={imageUrl} />
            <meta name="image" property="og:image:secure_url" content={imageUrl} />
            <meta property="og:url" content={url} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
        </Helmet>
    );
};
