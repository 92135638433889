import React, { useCallback, useEffect } from 'react';
import { matrixClient, useChatDispatch, EventType, MatrixEventType } from '@mediusoft/matrix-chat';

export const InitializeMatrix: React.FC = () => {
    const dispatch = useChatDispatch();

    const loadRooms = useCallback(() => {
        dispatch({ type: 'LOAD_ROOMS' });
    }, [dispatch]);
    useEffect(() => {
        loadRooms();
        matrixClient.on(EventType.RoomAvatar, loadRooms);
        matrixClient.on(MatrixEventType.AccountData, loadRooms);
        matrixClient.on(MatrixEventType.RoomName, loadRooms);
        matrixClient.on(MatrixEventType.RoomStateEvents, loadRooms);
        matrixClient.on(MatrixEventType.RoomMyMembership, loadRooms);
        matrixClient.on(MatrixEventType.RoomTimeline, loadRooms);
        return () => {
            matrixClient.removeListener(EventType.RoomAvatar, loadRooms);
            matrixClient.removeListener(MatrixEventType.AccountData, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomName, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomStateEvents, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomMyMembership, loadRooms);
            matrixClient.removeListener(MatrixEventType.RoomTimeline, loadRooms);
        };
    }, [matrixClient]);

    return <></>;
};
