import { Button, Dialog, Typography, Box, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './confirm-dialog.style';

export type ConfirmDialogType = 'error' | 'success';
export type ConfirmDialogDescription = string | undefined;

type ConfirmDialogProps = {
    open: boolean;
    onClose: (type: ConfirmDialogType, description: ConfirmDialogDescription) => void;
    onConfirm: any;
    confirmText?: string;
    description?: ConfirmDialogDescription;
    type?: ConfirmDialogType;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open,
    confirmText,
    description,
    type = 'error',
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const classes = useStyle();
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle className={classes.headerWrapper}>
                    <Box className={classes.header}>
                        <IconButton
                            size="small"
                            onClick={() => onClose(type, description)}
                            className={classes.closeIcon}
                        >
                            <CloseIcon />
                        </IconButton>
                        {type === 'success' ? (
                            <img className={classes.icon} src={require(`assets/confirm.svg`)} />
                        ) : (
                            <img className={classes.icon} src={require(`assets/delete.svg`)} />
                        )}
                        <Typography color={type === 'success' ? 'primary' : 'secondary'}>
                            {confirmText || t('confirmDialog:confirmText')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent className={classes.contentWrapper}>
                    <Box className={classes.content}>
                        <Typography className={classes.description}>
                            {description || t('confirmDialog:deleteDescription')}
                        </Typography>
                        <Box className={classes.buttons}>
                            <Button
                                onClick={() => onClose(type, description)}
                                variant="outlined"
                                autoFocus
                                className={classes.button}
                            >
                                {t('confirmDialog:no')}
                            </Button>
                            <Button
                                onClick={onConfirm}
                                variant="contained"
                                color={type === 'success' ? 'primary' : 'secondary'}
                                className={classes.button}
                            >
                                {t('confirmDialog:yes')}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};
