import { Box, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useStyles } from './empty-state.style';

type EmptyStateProps = {
    media: ReactNode;
    primaryText: string;
    secondaryText?: string;
    actionButton?: ReactNode;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
    media,
    primaryText,
    secondaryText,
    actionButton,
    children,
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box>{media}</Box>
            {children && <Box>{children}</Box>}
            <Box mt={5}>
                <Typography variant="h2">{primaryText}</Typography>
            </Box>
            {secondaryText && (
                <Box mt={1}>
                    <Typography variant="h5" color="textSecondary">
                        {secondaryText}
                    </Typography>
                </Box>
            )}
            {actionButton && <Box mt={3}>{actionButton}</Box>}
        </Box>
    );
};
