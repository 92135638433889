import LuxonUtils from '@date-io/luxon';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { NotificationsProvider } from 'context/NotificationsContext';
import { useSettings } from 'context/SettingsContext';
import { DevicePlatform, useRegisterDeviceMutation } from 'graphql/generated';
import React, { useEffect } from 'react';
import Routes from 'routes';
import smoothscroll from 'smoothscroll-polyfill';
import customTheme from 'theme';

const App: React.FC = () => {
    const { theme } = useSettings();
    const muiTheme = customTheme(theme);

    const setVapidDetails = {
        subject: 'mailto: <noreply@staffy.az>',
        publicKey: 'BHVBOZw23YZHbYBbkQ1YT5kLukUc5ME5IwpgSTv3ffDgcCKT1-LYX8ABtcQl_wWNfmEfSblBZSwyeQshvGjDSsg',
        privateKey: 'cVi_9sLivFcOGYmjyJFKjBKw0beMSJlQ_w9lPB9tShA',
    };
    const [registerDeviceMutation] = useRegisterDeviceMutation();

    const getSubscribed = async () => {
        let subscription;
        if ('serviceWorker' in navigator) {
            await navigator.serviceWorker.register('./sw.js');
            const sw = await navigator.serviceWorker.ready;

            if (sw.pushManager) {
                subscription = await sw.pushManager.getSubscription();
                if (!subscription) {
                    subscription = await sw.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: setVapidDetails.publicKey,
                    });
                }
                registerDeviceMutation({
                    variables: {
                        input: {
                            platform: DevicePlatform.Web,
                            pushToken: JSON.stringify(subscription),
                        },
                    },
                });
            }
        }
    };
    const subscriptionListener = async () => {
        if (Notification.permission == 'granted') {
            await getSubscribed();
        } else if (Notification.permission !== 'denied') {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                await getSubscribed();
            }
        }
    };

    useEffect(() => {
        subscriptionListener();
        smoothscroll.polyfill();
    }, []);

    return (
        <MuiPickersUtilsProvider locale={'en'} utils={LuxonUtils}>
            <ThemeProvider theme={muiTheme}>
                <NotificationsProvider>
                    <CssBaseline />
                    <Routes />
                </NotificationsProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
