import FormControl from '@material-ui/core/FormControl';
import { AutoCompleteInput } from 'components/autocomplete';
import { Field, FieldProps, useField } from 'formik';
import { useCompanyLazyQuery, useCompanyListLazyQuery } from 'graphql/generated';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useStyles } from './companies.style';

export type CompanyPropTypes = {
    error?: boolean | undefined;
    touched?: boolean | undefined;
};

type CompanyOption = {
    id: string;
    name: string;
};

export const CompanyInput: React.FC<CompanyPropTypes> = ({ error, touched }) => {
    const [field] = useField('companyId');
    const fieldValue = field.value;
    const classes = useStyles();
    const [value, setValue] = React.useState<CompanyOption | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<CompanyOption[]>([]);

    const [loadCompanies, { data, loading }] = useCompanyListLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const [loadCompany, { data: companyData, loading: companyLoading }] = useCompanyLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const filterCompanies = useMemo(
        () => _.debounce((name?: string) => loadCompanies({ variables: { where: { name } } }), 500),
        [],
    );

    useEffect(() => {
        if (!companyData && fieldValue) {
            loadCompany({ variables: { id: fieldValue } });
        }

        if (companyData) {
            const option = companyData.company as CompanyOption;
            setValue(option);
            setOptions([option]);
        }
    }, [companyData, fieldValue]);

    useEffect(() => {
        if (inputValue && inputValue !== value?.name) {
            filterCompanies(inputValue);
        } else if (inputValue.length === 0) {
            setOptions([]);
        }
    }, [inputValue]);

    useEffect(() => {
        const companies = data?.companies.edges.map((item) => item.node);
        if (companies) {
            setOptions(companies);
        }
    }, [data]);

    return (
        <FormControl className={classes.root} variant="outlined" fullWidth error={touched && error}>
            <Field name="companyId">
                {({ field: { name }, form: { setFieldValue } }: FieldProps): React.ReactNode => (
                    <AutoCompleteInput
                        options={options}
                        value={value && options.some((o) => o.id === value.id) ? value : null}
                        onChange={(_, newValue: CompanyOption | null): void => {
                            setFieldValue(name, newValue?.id);
                            setValue(newValue);
                        }}
                        onInputChange={(_, newInputValue): void => {
                            setInputValue(newInputValue);
                        }}
                        getOptionSelected={(option: CompanyOption, value: CompanyOption): boolean => {
                            return option?.id === value?.id;
                        }}
                        getOptionLabel={(option: CompanyOption): string => option?.name}
                        loading={loading || companyLoading}
                        error={error}
                        touched={touched}
                    />
                )}
            </Field>
        </FormControl>
    );
};
