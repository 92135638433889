export const namespaces = [
    'about',
    'homePage',
    'header',
    'jobList',
    'jobTable',
    'jobseekerList',
    'blogList',
    'blogDetail',
    'blogForm',
    'adminDrawer',
    'jobDetails',
    'companyList',
    'savedSearchList',
    'jobTags',
    'pricing',
    'pricingPlans',
    'filter',
    'category',
    'calendar',
    'confirmDialog',
    'employer',
    'employerProfile',
    'jobForm',
    'changePasswordForm',
    'employerRegistration',
    'signIn',
    'forgotPassword',
    'verify',
    'resetPassword',
    'auth',
    'employerDrawer',
    'jobSeekerDrawer',
    'adminDrawer',
    'hiringProcess',
    'triggerForm',
    'createJobMessages',
    'candidates',
    'companyDetail',
    'dashboardEmpApplication',
    'eventSchedule',
    'jobSeeker',
    'jobSeekerProfile',
    'jobCandidates',
    'jobseekerPublicProfile',
    'jobSeekerProfileMessages',
    'jobseekerStatusTypes',
    'mainProfileMenu',
    'enum',
    'roles',
    'common',
    'footer',
    'region',
    'payment',
    'applicantProfileRequirement',
    'progressIndicator',
    'calendarEvents',
    'eventTemplates',
    'jobApplicationsSearchResults',
    'navigationMenu',
    'jobSeekerRegistration',
    'privacyPolicy',
    'error',
    'setting',
    'routeLeavingGuard',
    'chat',
];
