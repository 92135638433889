import { makeStyles } from '@material-ui/core/styles';
export const useStyle = makeStyles((theme) => {
    return {
        autoComplete: {
            height: '100%',
            '& > div': {
                height: '100%',
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: theme.spacing(0.5),
                height: '100%',
            },
        },
        borderless: {
            '& .MuiOutlinedInput-root fieldset': {
                border: 'none',
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: '1px',
            },
        },
    };
});
