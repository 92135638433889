import { Box, Card, Grid, Tooltip, Typography, Zoom } from '@material-ui/core';
import clsx from 'clsx';
import { JobseekerTags, Link } from 'components';
import { defaultAvatar } from 'config';
import { useAuth } from 'context/AuthContext';
import { ViewType } from 'enum';
import { Jobseeker, JobseekerStatus } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyle } from './jobseeker-grid-item.style';

type JobseekerGridItemProps = {
    jobseeker: Jobseeker;
    bookmarkJob?: any;
};

export const JobseekerGridItem: React.FC<JobseekerGridItemProps> = ({ jobseeker }) => {
    const { id, firstName, lastName, avatar, category, region, salaryExpectation, status, keySkills } = jobseeker;

    const { t } = useTranslation();

    const classes = useStyle();

    const { isEmployer, isAdmin } = useAuth();

    const translatedStatus = status ? t(`enum:jobseekerStatus_${status}`) : '';

    const color =
        {
            [JobseekerStatus.Employed]: '#F10000',
            [JobseekerStatus.OpenToOpportunities]: '#47DF00',
        }[status || ''] || '#C4C4C4';

    return (
        <Link to={`/jobseeker/${id}`}>
            <Card className={classes.container}>
                <Grid container className={classes.gridContainer}>
                    <Grid xs={12} className={classes.profileInfoContainer}>
                        <div className={classes.logoContainer}>
                            <Tooltip TransitionComponent={Zoom} placement="bottom" title={translatedStatus}>
                                <img
                                    src={avatar?.url || defaultAvatar}
                                    alt="Jobseeker avatar"
                                    style={{
                                        color,
                                    }}
                                />
                            </Tooltip>
                        </div>

                        <div className={classes.profileInfo}>
                            <Typography variant="h4">{`${firstName} ${lastName}`}</Typography>
                            {region && <Typography variant="h5">{region?.name}</Typography>}
                        </div>
                    </Grid>

                    {(salaryExpectation || category) && (
                        <Grid xs={12} className={classes.categorySalarySection}>
                            <div className={classes.categoryContainer}>
                                {category && (
                                    <Typography
                                        variant="h6"
                                        className={clsx(classes[`color${category?.order % 7}`], classes.category)}
                                    >
                                        {t(`category:${category?.name}`)}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.salaryContainer}>
                                {salaryExpectation && (isEmployer || isAdmin) && (
                                    <Typography variant="h6"> {salaryExpectation} AZN </Typography>
                                )}
                            </div>
                        </Grid>
                    )}

                    {keySkills && keySkills?.length > 0 && (
                        <Grid xs={12} className={classes.keySkills}>
                            <Box mt={3} flexGrow={1}>
                                <JobseekerTags viewType={ViewType.Grid} {...{ jobseeker }} />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Link>
    );
};
