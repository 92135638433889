import * as Sentry from '@sentry/react';
import { Spinner } from 'components';
import { useAuth } from 'context/AuthContext';
import { useSettings } from 'context/SettingsContext';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Analytics } from 'utils/analytics';
import browserHistory from 'utils/browser-utils';
import AuthorizedRoute from './AuthorizedRoute';

const DashboardLayout = lazy(() => import('views/layout/dashboard'));
const AppLayout = lazy(() => import('views/layout/app'));
const PublicLayout = lazy(() => import('views/layout/public'));

const Routes: React.FC = () => {
    const { user } = useAuth();
    const { language, theme } = useSettings();

    useEffect(() => {
        Sentry.setTags({ language, theme });

        if (user) {
            const userContext: Sentry.User = {
                email: user.email,
                username: `${user.firstName} ${user.lastName}`,
                ip_address: '{{auto}}',
            };
            Sentry.setUser(userContext);
            Sentry.setTag('role', user.role);
        } else {
            Sentry.setUser(null);
            Sentry.setTag('role', undefined);
        }
    }, [user, language, theme]);

    return (
        <Router history={browserHistory}>
            <Suspense fallback={<Spinner style={{ width: '100vw', height: '100vh' }} />}>
                <Route path="/" component={Analytics} />

                <Switch>
                    <Route path="/p" component={PublicLayout} />
                    <AuthorizedRoute path="/d" component={DashboardLayout} />
                    <Route path="/" component={AppLayout} />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default Routes;
