/* eslint-disable react/no-unescaped-entities */
import { Button, Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { Region, RegionConnection } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '650px',
            height: '600px',
            [theme.breakpoints.down('xs')]: {
                minWidth: '310px',
            },
        },
        close: {
            '& .MuiButton-label': {
                padding: '10px 0',
            },
        },
        treeView: {
            display: 'flex',
            padding: '30px',
            overflowY: 'auto',
            flex: 1,
            '& .MuiTreeView-root': {
                width: '100%',
            },
            '& .MuiTreeItem-content': {
                margin: '5px',
            },
        },
    };
});

type RegionModalProps = {
    isModalOpen: boolean;
    regionId?: string;
    onChange: any;
    onSave: any;
    onCancel: any;
    regions?: RegionConnection;
};

export const RegionModal: React.FC<RegionModalProps> = ({
    isModalOpen,
    onChange,
    onCancel,
    onSave,
    regions,
    regionId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const renderTree = (regionNode: Region): React.ReactNode => {
        return (
            <Grid key={regionNode.id} item xs={12}>
                <TreeItem
                    onLabelClick={(event): void => {
                        onChange(regionNode.id);
                        event.preventDefault();
                    }}
                    nodeId={regionNode.id}
                    label={t(`region:${regionNode.name}`)}
                >
                    {regionNode.children?.edges
                        ?.map(({ node }) => node)
                        .sort((a, b) => t(`region:${a.name}`).localeCompare(t(`region:${b.name}`)))
                        .map((node) => renderTree(node))}
                </TreeItem>
            </Grid>
        );
    };

    return (
        <Dialog disableBackdropClick open={isModalOpen} classes={{ paper: classes.root }}>
            <div className={classes.treeView}>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultSelected={regionId || ''}
                    selected={regionId || ''}
                >
                    <Grid container>
                        {regions?.edges
                            ?.map(({ node }) => node)
                            .sort((a, b) => t(`region:${a.name}`).localeCompare(t(`region:${b.name}`)))
                            .map((node) => renderTree(node))}
                    </Grid>
                </TreeView>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={{ margin: 10, marginLeft: 0 }} onClick={onCancel}>
                    {t('region:cancelRegion')}
                </Button>
                <Button style={{ margin: 10, marginLeft: 0 }} variant="contained" color="primary" onClick={onSave}>
                    {t('region:chooseRegion')}
                </Button>
            </div>
        </Dialog>
    );
};
