import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
    ratio: {
        height: 0,
        position: 'relative',
        paddingBottom: '56.25%',
        display: 'flex !important',
    },
    ratioContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

type RatioBoxProps = {
    containerClassName?: string;
    containerStyles?: any;
    contentClassName?: string;
    contentStyles?: any;
};

export const RatioBox: React.FC<RatioBoxProps> = ({
    children,
    containerClassName,
    contentClassName,
    containerStyles,
    contentStyles,
}) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.ratio, containerClassName)} style={containerStyles}>
            <div className={clsx(classes.ratioContent, contentClassName)} style={contentStyles}>
                {children}
            </div>
        </div>
    );
};
