import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { EducationLevel } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField, BaseFieldProps } from '../base-field';

type EducationFieldProps = {
    label?: string | undefined;
    name: string;
} & BaseFieldProps;

export const EducationField: React.FC<EducationFieldProps> = ({ label, name, allowClear }) => {
    const { t } = useTranslation();
    return (
        <BaseField
            label={label}
            name={name}
            component={TextField}
            select
            fullWidth
            variant="outlined"
            allowClear={allowClear}
        >
            <MenuItem value={EducationLevel.HighSchool}>{t(`enum:education_HIGH_SCHOOL`)}</MenuItem>
            <MenuItem value={EducationLevel.College}>{t('enum:education_COLLEGE')}</MenuItem>
            <MenuItem value={EducationLevel.Bachelor}>{t('enum:education_BACHELOR')}</MenuItem>
            <MenuItem value={EducationLevel.Master}>{t('enum:education_MASTER')}</MenuItem>
            <MenuItem value={EducationLevel.Phd}>{t('enum:education_PHD')}</MenuItem>
        </BaseField>
    );
};
