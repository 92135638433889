import { Box, Button, Hidden, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RepeatIcon from '@material-ui/icons/Repeat';
import EditIcon from '@material-ui/icons/Edit';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FeatureLink, Link } from 'components';
import { Spinner } from 'components/spinner';
import { useNotifications } from 'context/NotificationsContext';
import { usePricingPlan } from 'context/PricingPlanContext';
import {
    FeatureCode,
    Job,
    JobStatus,
    PurchasedPlan,
    useCurrentPlanLazyQuery,
    useCurrentPlanQuery,
    useDeleteJobMutation,
    usePublishJobMutation,
    useReactivateJobMutation,
} from 'graphql/generated';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFromCache } from 'utils/apollo-helper';
import { formatDate, getDateText } from 'utils/dateTimeHelper';
import { useStyle } from './job-posting-item.style';
import { logAnalyticsEvent } from 'ui-services/analytics.ui-service';
import { GoogleAnaliticsEvent } from 'enum';

type JobPostingItemProps = {
    job: Job;
    readonly?: boolean;
};

export const JobPostingItem: React.FC<JobPostingItemProps> = ({ job, readonly }) => {
    const { id, slug, title, status, createdAt, publishedAt, expiresAt, applicationCount, company, __typename } = job;
    const classes = useStyle();
    const { t } = useTranslation();
    const { notify, confirm } = useNotifications();
    const { withFeatureCheck, showSlotDialog, isFeatureEnabled } = usePricingPlan();

    const expired = new Date(expiresAt).getTime() < new Date().getTime();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [publishJob] = usePublishJobMutation({
        onCompleted() {
            logAnalyticsEvent(GoogleAnaliticsEvent.PUBLISHED_JOB);
            notify({
                type: 'success',
                message: t('employer:jobPublishSuccess'),
            });
            handleClose();
        },
    });
    const [reactivateJob] = useReactivateJobMutation({
        onCompleted() {
            notify({
                type: 'success',
                message: t('employer:jobReactivatedSuccess'),
            });
        },
    });

    const [loadCurrentPlan, { loading }] = useCurrentPlanLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted({ currentPlan }) {
            withFeatureCheck(
                currentPlan as PurchasedPlan,
                FeatureCode.CreateJobListing,
                () => publishJob({ variables: { job: { jobId: id } } }),
                () => showSlotDialog(id),
            );
        },
    });

    const [deleteJob] = useDeleteJobMutation({
        onCompleted() {
            notify({
                type: 'success',
                message: t('employer:jobDeleteSuccess'),
            });
        },
    });

    const handleDelete = (e) => {
        e.preventDefault();
        confirm({
            onConfirm: () =>
                deleteJob({
                    variables: { id },
                    update(cache) {
                        DeleteFromCache(cache, id, __typename);
                    },
                }),
        });
    };

    const statusClassNameMap = {
        [JobStatus.OnModeration]: classes.statusOnModeration,
        [JobStatus.Blocked]: classes.statusBlocked,
        [JobStatus.Published]: classes.statusPublished,
        [JobStatus.Draft]: classes.statusDraft,
    };

    const statusTextMap = {
        [JobStatus.OnModeration]: t(`enum:jobStatus_${JobStatus.OnModeration}`),
        [JobStatus.Blocked]: t(`enum:jobStatus_${JobStatus.Blocked}`),
        [JobStatus.Published]: t(`enum:jobStatus_${JobStatus.Published}`),
        [JobStatus.Draft]: t(`enum:jobStatus_${JobStatus.Draft}`),
    };
    const { data } = useCurrentPlanQuery({
        fetchPolicy: 'network-only',
    });
    const currentPlan = data?.currentPlan;
    const isEnabled = isFeatureEnabled(currentPlan as PurchasedPlan, FeatureCode.ReactivateJobListing) && expired;

    return (
        <Link to={`/job/${slug}`}>
            <Box className={classes.root}>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <Typography variant="h5" className={classes.title}>
                            {title}
                        </Typography>
                        {readonly && (
                            <Link link to={company.slug ? `/${company.slug}` : `/company/${company?.id}`}>
                                <Typography variant="h6" color="textSecondary">
                                    {company.name}
                                </Typography>
                            </Link>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {!readonly && status === JobStatus.Draft && (
                            <Button
                                disabled={loading}
                                variant="contained"
                                className={classes.publishButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    loadCurrentPlan();
                                }}
                                startIcon={
                                    loading ? (
                                        <Spinner
                                            color="inherit"
                                            style={{ width: 20, height: 20 }}
                                            spinnerStyle={{ width: 16, height: 16 }}
                                        />
                                    ) : (
                                        <PublishIcon />
                                    )
                                }
                            >
                                <Hidden xsDown>{t('employer:publish') as string}</Hidden>
                            </Button>
                        )}
                        {!readonly && (
                            <Box className={classes.actions}>
                                <IconButton style={{ height: 'fit-content' }} onClick={handleClick}>
                                    <MoreVerticalIcon />
                                </IconButton>
                                <Menu
                                    classes={{ list: classes.menu }}
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={(e: any) => {
                                        e.preventDefault();
                                        handleClose();
                                    }}
                                >
                                    {isEnabled && (
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={() => {
                                                reactivateJob({ variables: { job: { jobId: id } } });
                                            }}
                                        >
                                            <RepeatIcon className={classes.menuItemIcon} />
                                            {t('employer:reactivate')}
                                        </MenuItem>
                                    )}
                                    <Link to={`/d/candidates/job/${slug}`}>
                                        <MenuItem className={classes.menuItem} onClick={handleClose}>
                                            <SupervisorAccountIcon className={classes.menuItemIcon} />
                                            {t('employer:manageCandidates')}
                                        </MenuItem>
                                    </Link>
                                    {status === JobStatus.Draft && (
                                        <MenuItem
                                            className={classes.menuItem}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                loadCurrentPlan();
                                            }}
                                        >
                                            <PublishIcon className={classes.menuItemIcon} />
                                            {t('employer:publish')}
                                            {loading && <Spinner button />}
                                        </MenuItem>
                                    )}
                                    <FeatureLink featureCode={FeatureCode.CreateJobListing} to={`/d/job/${id}/edit`}>
                                        <MenuItem className={classes.menuItem} onClick={handleClose}>
                                            <EditIcon className={classes.menuItemIcon} />
                                            {t('employer:edit')}
                                        </MenuItem>
                                    </FeatureLink>
                                    <FeatureLink
                                        featureCode={FeatureCode.CopyJobListing}
                                        to={`/d/job/create?source_id=${id}`}
                                    >
                                        <MenuItem className={classes.menuItem} onClick={handleClose}>
                                            <FileCopyIcon className={classes.menuItemIcon} />
                                            {t('employer:copy')}
                                        </MenuItem>
                                    </FeatureLink>
                                    <MenuItem className={classes.menuItem} onClick={handleDelete}>
                                        <DeleteIcon className={classes.menuItemIcon} />
                                        {t('employer:delete')}
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    <Tooltip placement="top" arrow title={t('employer:postedDate') || ''}>
                        <Box className={classes.tag}>
                            <Typography variant="body2" className={classes.date}>
                                <EventAvailableIcon className={classes.tagIcon} />
                                {getDateText(status === JobStatus.Published ? publishedAt : createdAt)}
                            </Typography>
                        </Box>
                    </Tooltip>
                    {!expired && (
                        <Tooltip placement="top" arrow title={t('employer:expiringDate') || ''}>
                            <Box className={classes.tag}>
                                <Typography variant="body2" className={classes.date}>
                                    <EventBusyIcon className={classes.tagIcon} />
                                    {formatDate(expiresAt)}
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}
                    {status && (
                        <Tooltip placement="top" arrow title={t('employer:status') || ''}>
                            <Box className={classes.tag}>
                                <Typography variant="body2" className={statusClassNameMap[status]}>
                                    {statusTextMap[status]}
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}
                    <Tooltip placement="top" arrow title={t('viewCount') || ''}>
                        <Box className={classes.tag}>
                            <Typography variant="body2" className={classes.viewCountTag}>
                                <VisibilityIcon className={classes.menuItemIcon} /> {job?.viewCount || 0}
                            </Typography>
                        </Box>
                    </Tooltip>

                    {!readonly && (
                        <Link to={`/d/candidates/job/${slug}`}>
                            <Tooltip placement="top" arrow title={t('employer:candidateCount') || ''}>
                                <Box className={classes.tag}>
                                    <Typography variant="body2" className={classes.candidateCount}>
                                        <SupervisorAccountIcon className={classes.tagIcon} />
                                        {applicationCount || 0}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Link>
                    )}
                </Box>
            </Box>
        </Link>
    );
};
