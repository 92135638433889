import { IconButton, IconButtonProps } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import React from 'react';

type BookmarkProps = {
    size?: 'small' | 'medium';
    bookmarked?: boolean | null;
    onClick?: any;
    playerName?: string;
} & IconButtonProps;

const BookmarkCompnent: React.FC<BookmarkProps> = ({ bookmarked, onClick, ...props }) => {
    return (
        <IconButton
            {...props}
            onClick={(event): void => {
                onClick();
                event.stopPropagation();
                event.preventDefault();
            }}
            color={bookmarked ? 'primary' : 'inherit'}
        >
            {bookmarked ? (
                <BookmarkIcon style={{ opacity: bookmarked ? 1 : 0.5 }} />
            ) : (
                <BookmarkBorderIcon style={{ opacity: bookmarked ? 1 : 0.5 }} />
            )}
        </IconButton>
    );
};

export const Bookmark = React.memo(BookmarkCompnent, (prevProps, nextProps): boolean => {
    return prevProps.bookmarked === nextProps.bookmarked;
});
