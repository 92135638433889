import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => {
    const tagStyle = {
        width: 'fit-content',
        padding: theme.spacing(0.4, 0.5),
        fontSize: '0.7rem',
        borderRadius: 4,
        fontWeight: 600,
    };
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'flex-start !important',
            },
        },
        skills: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        skill: {
            ...tagStyle,
            marginRight: theme.spacing(0.75),
            marginBottom: theme.spacing(0.75),
            border: 'none',
            backgroundColor: `${theme.palette.primary.main}18`,
            height: 22,
        },
        skillMore: {
            border: `1px solid ${theme.palette.primary.main}80`,
        },
        salaryTag: {
            ...tagStyle,
            color: '#4caf50',
            backgroundColor: 'rgba(76, 175, 80, 0.08)',
        },
        locationTag: {
            ...tagStyle,
            color: '#2196F3',
            backgroundColor: 'rgba(33, 150, 243, 0.08)',
        },
        none: {
            display: 'none',
        },
        hidden: {
            visibility: 'hidden',
        },
    };
});
